import { reactive, nextTick, ref, onMounted, watch } from 'vue';
import draggable from 'vuedraggable'
import fetchWrapper from '../../../modules/fetch-wrapper'
import vClickOutside from 'click-outside-vue3'

export default {
	directives: {
		clickOutside: vClickOutside.directive
	},
	components: { draggable },
	setup(){
		let chooseFileTarget = null;
		const maxFilesToChoose = ref(1);
		const isModalVisible = ref(false)
		const folderStructure = ref([])
		const files = ref([])
		const drag = ref(false)
		const actuallyUploadedFiles = ref([])

		const isNewFolderAdding = ref(false)
		const newFolderAddingParentId = ref(null)
		const editedFolderId = ref(null)
		const activeFolderId = ref(null)
		const chosenImagesId = ref([])
		const chosenImages = ref([])
		const isFilesLoading = ref(false)
		const pagination = ref({})
		const searchPhrase = ref('')
		const searchDate = ref('')
		const imageMenuPosition = ref({x: null, y: null})
		const imageId = ref(null)
		const imageMenuExtended = ref(false)
		const isToggledByFilepicker = ref(false);

		let callbackOnModalClose = null;

		const addNewFolderHandler = async (e, parent) => {
			if(e.target.value){
				const [status, response] = await fetchWrapper({url: '/api/file-structure', method: 'POST', body: { title: e.target.value, parent }})

				// Handle error
				if(status >= 300) return

				if(!parent){
					folderStructure.value.push({
						"_id": response.body._id,
						title: response.body.title,
					})
				}

				if(parent){
					folderStructure.value.forEach((x, i) => {
						if(x._id == parent){
							if(!folderStructure.value[i]._children) folderStructure.value[i]._children = [];
							folderStructure.value[i]._children.push({
								"_id": response.body._id,
								title: response.body.title,
							})
						}
					});
				}
			}

			isNewFolderAdding.value = false;
		}

		const editFolderHandler = async (e, id) => {
			if(e.target.value){
				const [status, response] = await fetchWrapper({url: `/api/file-structure/${id}`, method: 'PUT', body: { title: e.target.value }})
				// Handle error
				if(status >= 300) return

				folderStructure.value.forEach((x, i) => {
					if(x._id == id) folderStructure.value[i].title = e.target.value;

					if(x._children && x._children.length > 0){
						folderStructure.value[i]._children.forEach((y, i2) => {
							if(y._id == id) folderStructure.value[i]._children[i2].title = e.target.value;
						})
					}
				});
			}

			editedFolderId.value = null;
		}

		const toggleModalHandler = () => {
			isModalVisible.value = !isModalVisible.value
		}

		const removeFolderHandler = async (id, title) => {
			const flag = confirm(`Czy na pewno chcesz usunąć folder ${title}`);
			if(!flag) return

			const [status, response] = await fetchWrapper({url: `/api/file-structure/${id}`, method: 'DELETE'})

			if(status >= 300) return

			folderStructure.value.forEach((x, i) => {
				if(x._id == id) folderStructure.value.splice(i, 1);
				if(x._children && x._children.length > 0){
					folderStructure.value[i]._children.forEach((y, i2) => {
						if(y._id == id) folderStructure.value[i]._children.splice(i2, 1);
					})
				}
			})
		}

		const chooseImagesHandler = (e, id) => {
			if(e.shiftKey && chosenImagesId.value.length > 0){
				// const index = files.value.findIndex(x => x._id == id);
				// chosenImagesId.value = [...chosenImagesId.value[0]]
				// const indexStart = files.value.findIndex(x => x._id == chosenImagesId.value[0]._id)
				// for(let i = indexStart; i < index + 1; indexStart <= index ? i++ : i--){
				// 	if(!chosenImagesId.value.includes(files.value[i]._id)) chosenImagesId.value.push(files.value[i]._id)
				// }
			}else if(e.ctrlKey){
				chosenImagesId.value.push(id)
			}else{
				chosenImagesId.value = [id]
			}
		}


		const changeActiveGallery = async (id) => {
			activeFolderId.value = id;
			isFilesLoading.value = true;

			const body = {
				limit: 50,
				skip: ((pagination.value.currentPage || 1) - 1) * 50,
				// skip: pagination.value.currentPage * 50
				phrase: searchPhrase.value,
				date: searchDate.value
				// sort: this.$route.query.sort || '',
			}

			const [status, response] = await fetchWrapper({url: `/api/file-structure/${id}/files`, method: 'GET', body})
			if(status >= 300) return;
			files.value = response.body
			isFilesLoading.value = false;
			response.pagination ? pagination.value = {...response.pagination, totalPages: response.pagination.total / (pagination.value.limit || 50), skip: ((pagination.value.currentPage || 1) - 1) * 50, limit: 50} : pagination.value = {}
		}

		const changePaginationPage = (page) => {
			pagination.value.currentPage = page
			changeActiveGallery(activeFolderId.value)
		}

		const uploadFile = async (file) => {
			const [status, response] = await fetchWrapper({url: `/admin/upload-file`, method: 'POST', body: {
				image: file,
				folderId: activeFolderId.value
			}})
			return [status, response]
		}

		const uploadFiles = async (filesArg) => {
			const newFiles = []
			for await ( const file of filesArg ){
				actuallyUploadedFiles.value.push({title: file.name, isFinished: false})
				const [status, result ] = await uploadFile(file)

				newFiles.push(result.body[0])
				actuallyUploadedFiles.value = actuallyUploadedFiles.value.map(x => {
					return {...x, isFinished: true }
				})
			}

			setTimeout(() => {
				files.value = [...newFiles, ...files.value]
			}, 750)
			setTimeout(() => {
				actuallyUploadedFiles.value = [];
			}, 2500)
		}

		const fileInputChangeHandler = async (e) => {
			actuallyUploadedFiles.value = []
			await uploadFiles(e.target.files);
			e.target.value = []
		}

		const removeChosenImageHandler = (id) => {
			chosenImages.value = chosenImages.value.filter(x => x._id != id)
			chosenImagesId.value = chosenImagesId.value.filter(x => x != id)
		}

		const setImageUrl = (image) => {
			if(image.meta.sizes && image.meta.sizes.length > 0){
				if(image.meta.sizes.includes('--xs')) return image.meta.relativePath + "/" + image.meta.grantedName + "--xs." + image.meta.extension
				else return image.filepath.replace('public', '');
			}else{
				return image.filepath.replace('public', '');
			}
		}

		const confirmSelectedFilesHandler = () => {
			if(chooseFileTarget){
				const files = chosenImages.value.map(x => x._id)
				chooseFileTarget.value = files.join(',')

				if(files.length > 1){

				}else{
					const imgDOM = document.querySelector(chooseFileTarget.getAttribute('data-img'))
					if(imgDOM && files.length == 1) imgDOM.src = "/"+ chosenImages.value[0].filepath
					if(imgDOM && files.length == 0) imgDOM.src = "/client/images/placeholder.png"

					const filenameDOM = document.querySelector(chooseFileTarget.getAttribute('data-filename'))
					if(filenameDOM && files.length == 1) filenameDOM.innerHTML = chosenImages.value[0].meta.originalName
					if(filenameDOM && files.length == 0) filenameDOM.innerHTML = ""
				}

				let inputEvent = new Event('change', {bubbles:true,cancelable: true});
				chooseFileTarget.dispatchEvent(inputEvent)
			}
			isModalVisible.value = false;

			if(callbackOnModalClose) callbackOnModalClose(chosenImages)
		}

		const deleteFile = async () => {
			const flag = confirm(`Czy na pewno chcesz usunąć plik? Zostanie on usunięty na zawsze z serwera`);
			if(!flag) return

			const [status, response] = await fetchWrapper({url: `/api/file/${imageId.value}`, method: 'DELETE'})
			if(status >= 300) return;
			files.value = files.value.filter(x => {
				return x._id != imageId.value
			})
		}

		const imageMenuHandler = (e, id) => {
			imageMenuPosition.value = { x: e.clientX, y: e.clientY }
			imageId.value = id
		}

		const moveFileToFolder = async (folderId) => {
			const flag = confirm(`Czy na pewno chcesz przenieść plik do innego folderu?`);
			if(!flag) return

			const [status, response] = await fetchWrapper({url: `/api/file-structure/${folderId}/file/${imageId.value}`, method: 'PUT'})
			if(status >= 300) return;
			files.value = files.value.filter(x => {
				return x._id != imageId.value
			})
			imageMenuExtended.value = false
			imageMenuPosition.value = { x: null, y: null }
		}

		window.chooseFile = function(e, callback){
			isToggledByFilepicker.value = true;
			isModalVisible.value = true;

			if(!callback){
				chooseFileTarget = e.target
				if(chooseFileTarget.value){
					if(chooseFileTarget.value.includes(',')) chosenImagesId.value = [ ...chooseFileTarget.value.split(',') ]
					else chosenImagesId.value = [ chooseFileTarget.value ]
				}
				if(chooseFileTarget.getAttribute('data-max-files')){
					maxFilesToChoose.value = Number(chooseFileTarget.getAttribute('data-max-files'))
				}
			}else if(callback){
				maxFilesToChoose.value = e.max;
				callbackOnModalClose = callback;
				// return callback(chosenImagesId);
			}
		}

		window.toggleFileManager = function(){
			isToggledByFilepicker.value = false
			isModalVisible.value = true;
		}

		watch(() => [...chosenImagesId.value], async () => {
			const idList = chosenImagesId.value
			if(idList && idList.length > 0){
				const [status, response] = await fetchWrapper({url: `/api/v2/files?ids=${idList.join(',')}`, method: 'GET'})
				if(status >= 300) return;
				chosenImages.value = response.body
			}else{
				chosenImages.value = []
			}
		})

		onMounted(async () => {
			const [status, response] = await fetchWrapper({url: '/api/file-structure', method: 'GET'})
			folderStructure.value = [{
				title: "Ogólne",
				isStatic: true,
				_id: 'null'
			}, ...response.body.map(x => {
				return {...x, contents: []}
			})];

			nextTick(() => {
				const container = document.querySelector('.js-upload-file-container');
				['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
					container.addEventListener(evt, function(e){
						e.preventDefault();
						e.stopPropagation();
					}.bind(this), false);
				}.bind(this));
	
				container.addEventListener('drop', async function(e){
					await uploadFiles(e.dataTransfer.files)
				}.bind(this));
			})
		})

		const onClickOutside = () => {
			imageMenuPosition.value = {x: null, y: null}
			imageMenuExtended.value = false
		}

		return {
			toggleModalHandler,
			folderStructure,
			isNewFolderAdding,
			newFolderAddingParentId,
			addNewFolderHandler,
			activeFolderId,
			changeActiveGallery,
			files,
			drag,
			chosenImagesId,
			chosenImages,
			removeChosenImageHandler,
			chooseImagesHandler,
			fileInputChangeHandler,
			actuallyUploadedFiles,
			editFolderHandler,
			editedFolderId,
			removeFolderHandler,
			isFilesLoading,
			setImageUrl,
			isModalVisible,
			confirmSelectedFilesHandler,
			maxFilesToChoose,
			deleteFile,
			pagination,
			changePaginationPage,
			searchPhrase,
			imageMenuHandler,
			imageMenuPosition,
			imageMenuExtended,
			moveFileToFolder,
			onClickOutside,
			searchDate,
			chooseFileTarget,
			isToggledByFilepicker
		}
	}
}