
export default (app) => {
	const vPagination = document.querySelector('#vue-pagination');
	app.component('v-pagination', {
		name: 'v-pagination',
		template: vPagination.innerHTML,
		props: {
			data: Object,
			changepage: Function
		},
		setup(props, ctx){
			const goToPage = (page) => props.changepage(page)

			return { goToPage }
		}
	})

}