import slugify from 'slugify'
import toggler from "../modules/toggler"
import fetchWrapper from '../modules/fetch-wrapper'
import moment from 'moment'


const slugInput = document.querySelector('.js-slug-input')
if(slugInput){
	const btn = document.querySelector('.js-slug-btn')
	if(slugInput.value){
		if(btn) btn.href = `https://fundacjaskarbowosci.pl/${slugInput.value}`
	}
	slugInput.addEventListener('change', function(){
		if(btn) btn.href = `https://fundacjaskarbowosci.pl/${slugInput.value}`
	})
}

const textSlugify = document.querySelectorAll('.js-slugify')
textSlugify.forEach(x => {
	const place = document.querySelector(x.getAttribute('data-place'))
	x.addEventListener('input', function(){
		if(!place) return
		place.value = slugify(x.value, { lower: true })

		const btn = document.querySelector('.js-slug-btn')
		if(btn) btn.href = `https://fundacjaskarbowosci.pl/${place.value}`
	})
})


console.log('tutaj??');
window.addItem = (wrapper, options) => {
	const wrapperDOM = document.querySelector(wrapper);
	const template = wrapperDOM.innerHTML

	const node = document.createElement('div')
	node.classList = wrapperDOM.classList
	node.innerHTML = template;
	if(options.countIndex){
		const index = wrapperDOM.parentNode.childElementCount
		node.innerHTML = node.innerHTML.replace(/{{index}}/g, index)
	}

	wrapperDOM.parentNode.appendChild(node)
}
try {
  toggler({
    toggleButton: ".js-user-profile",
    toggledItem: ".js-user-profile-dropdown",
  })
} catch (e) {}

const updateGallery = async (target, wrapper) => {
	const wrapperDOM = document.querySelector(wrapper);
	const template = wrapperDOM.innerHTML
	const files = target.value;

	while (wrapperDOM.parentNode.childNodes.length > 2) {
		wrapperDOM.parentNode.removeChild(wrapperDOM.parentNode.lastChild);
	}

	if(files){
		const idList = files.split(',')
		const [status, response] = await fetchWrapper({url: `/api/v2/files?ids=${idList.join(',')}`, method: 'GET'})
		response.body.forEach(x => {
			const node = document.createElement('div')
			node.classList = wrapperDOM.classList
			node.innerHTML = template;
			node.innerHTML = node.innerHTML.replace(/{{imgsrc}}/g, x.filepath)
			node.innerHTML = node.innerHTML.replace('{{title}}', x.meta.originalName)
			node.innerHTML = node.innerHTML.replace('{{date}}', moment(x.date).format('YYYY-MM-DD HH:mm:ss'))
			wrapperDOM.parentNode.appendChild(node)
		})
	}
}
const galleries = document.querySelectorAll('.js-filemanager-gallery-input')
galleries.forEach(gallery => {
	gallery.addEventListener('change', (e) => {
		updateGallery(e.target, gallery.getAttribute('data-template'))
	})

	updateGallery(gallery, gallery.getAttribute('data-template'))
})
