class FormDataFetcher {
  constructor(form) {
    this.formDOM;
    this.data = {};

    if(form instanceof Element || form instanceof HTMLDocument){
      this.formDOM = form
    }else{
      this.formDOM = document.querySelector(form);
    }

    this.fetchForm();
  }


  fetchForm(){
    const inputsDOM = this.formDOM.querySelectorAll('[name]');

    inputsDOM.forEach(input => {
      if((input || {}).type === 'checkbox'){
        if(input.checked){
          this.append(input.name, input.value);
        }
      }else{
        this.append(input.name, input.value);
      }
    })
  }

  append(name, value){
    if(name.endsWith('[]')){
      if(!this.data.hasOwnProperty(name)) this.data[name] = [];
      this.data[name].push(value);
    }else{
      this.data[name] = value;
    }
  }
}

export default FormDataFetcher;