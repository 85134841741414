export default class Notify {
  constructor({ text, duration = 3000, type } = {}) {
    const wrapper = this.makeWrapper()
    const element = this.view({ text, type })

    wrapper.appendChild(element)

    this.fadeOut(element, duration)
  }

  fadeOut(element, duration) {
    setTimeout(() => {
      element.classList.add("opacity-0")
      setTimeout(() => {
        element.remove()
      }, duration + 500)
    }, duration)
  }

  view({ text, type } = {}) {
		console.log('view')
    const div = document.createElement("div")
    if (type === "success") div.classList = "notify-item--success"
    if (type === "info") div.classList = ""
    if (type === "warn") div.classList = ""
    if (type === "error") div.classList = "notify-item--error"
    div.classList += " notify-item"

    div.insertAdjacentHTML("beforeend", text)
    return div
  }

  makeWrapper() {
    let wrapper = document.querySelector(".js-notify-wrapper")
    if (!wrapper) {
      const div = document.createElement("div")
      div.classList = "notify-wrapper js-notify-wrapper"
      document.body.appendChild(div)
      wrapper = div
    }

    return wrapper
  }
}
