import Choices from "choices.js"
import axios from "../vendors/axios"
import updateURLParameter from "../methods/updateUrlParameter"

try {
  const choices = new Choices(".js-choice", {
    itemSelectText: "Naciśnij, aby wybrać",
    searchResultLimit: 4,
    searchFields: ["value"],
  })
} catch (err) {}

try {
  let choices = new Choices(".js-choice-boolean", {
    itemSelectText: "Naciśnij, aby wybrać",
    searchResultLimit: 4,
    searchFields: ["value"],
    items: [
      { value: "", label: "Wybierz", placeholder: true },
      { value: "1", label: "Pozytywny" },
      { value: "2", label: "Negatywny" },
      { value: "3", label: "Do poprawy" },
    ],
  })

  if (!Array.isArray(choices)) choices = [choices]
  if (Array.isArray(choices)) {
    choices.forEach((x) => {
      if (x && x.passedElement) {
        console.log(x, x.passedElement.element.getAttribute("data-value"))
        x.setChoiceByValue(x.passedElement.element.getAttribute("data-value"))
        if (x.passedElement.element.getAttribute("data-value") == "1") x.containerOuter.element.querySelector(".choices__inner").style.color = "#74C79D"
        if (x.passedElement.element.getAttribute("data-value") == "2") x.containerOuter.element.querySelector(".choices__inner").style.color = "#ff0000"
        if (x.passedElement.element.getAttribute("data-value") == "3") x.containerOuter.element.querySelector(".choices__inner").style.color = "#3364ed"

        x.passedElement.element.addEventListener("change", function (event) {
          if (event.detail.value == "1") x.containerOuter.element.querySelector(".choices__inner").style.color = "#74C79D"
          if (event.detail.value == "2") x.containerOuter.element.querySelector(".choices__inner").style.color = "#ff0000"
          if (event.detail.value == "3") x.containerOuter.element.querySelector(".choices__inner").style.color = "#3364ed"
          if (event.detail.value == "") x.containerOuter.element.querySelector(".choices__inner").style.color = "#000"
        })
      }
    })
  }
} catch (err) {}

try {
  const choicesSort = new Choices(".js-choice-sort")

  choicesSort.passedElement.element.addEventListener("change", function (e) {
    console.log(e.detail.value)
    if (e.detail.value !== "") {
      const newURL = updateURLParameter(window.location.href, "sort", e.detail.value)
      window.location.href = newURL
    }
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                  PROTEGES                                  */
/* -------------------------------------------------------------------------- */
try {
  new Choices(".js-choice-proteges", {
    removeItemButton: true,
    placeholder: true,
    itemSelectText: "Naciśnij, aby wybrać",
    placeholderValue: "Użytkownicy",
    noChoicesText: "Brak więcej opcji",
    duplicateItemsAllowed: false,
  }).ajax(async (callback) => {
    const response = await axios.get("/api/users/list?role=protege")
    const mappedResponse = response.data.items.map((item) => {
      return {
        value: item._id,
        label: item.fullname ? item.fullname : item._id,
      }
    })

    callback(mappedResponse, "value", "label")
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                                 VOLUNTEERS                                 */
/* -------------------------------------------------------------------------- */
try {
  new Choices(".js-choice-volunteer", {
    removeItemButton: true,
    placeholder: true,
    itemSelectText: "Naciśnij, aby wybrać",
    placeholderValue: "wolontariusze",
    noChoicesText: "Brak więcej opcji",
    duplicateItemsAllowed: false,
  }).ajax(async (callback) => {
    const response = await axios.get("/api/users/list?role=volunteer")
    const mappedResponse = response.data.items.map((data) => {
      return {
        value: data._id,
        label: data.fullname ? data.fullname : data._id,
      }
    })

    callback(mappedResponse, "value", "label")
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                                  PROJECTS                                  */
/* -------------------------------------------------------------------------- */
try {
  const choicesVolunteer = new Choices(".js-choice-projects", {
    removeItemButton: true,
    placeholder: true,
    itemSelectText: "Naciśnij, aby wybrać",
    placeholderValue: "Wybierz projekt",
    noChoicesText: "Brak więcej opcji",
    duplicateItemsAllowed: false,
  }).ajax(async (callback) => {
    const response = await axios.get("/api/project?dataComplex=small&multiple=true")

    const mappedResponse = response.data.map((data) => {
      return {
        value: data._id,
        label: data.title ? data.title : data._id,
      }
    })

    callback(mappedResponse, "value", "label")
  })
} catch (e) {}
