import sendDataHandler from '../modules/sendDataHandler';
import updateURLParameter  from '../methods/updateUrlParameter';


export default class Pagination {
	constructor(wrapper, options) {
    this.wrapperDOM = document.querySelector(wrapper);
    this.renderPlace = document.querySelector(options.renderPlace);
    this.paginationDOM = document.querySelector(options.paginationDOM);
    this.fetchApi = options.fetchApi;
		this.renderCallback = options.renderCallback;
		this.advancedPagination = false || options.advancedPagination

    this.paginationData;
    this._makeApiCallTable();
    this._bindEvents();
  }

  _bindEvents(){
    window.addEventListener('popstate', () => {
      this._makeApiCallTable()
    });
  }

  _makeApiCallTable(page){
    /** Set new url */
    if(page){
      let newURL = updateURLParameter(window.location.href, 'page', page);
      window.history.replaceState({}, null, newURL)
      window.history.pushState({}, null, newURL);
    }

    let apiUrl = `${this.fetchApi}`;

    /** Update query params with params already in user url */
    const params = new URLSearchParams(window.location.search.substr(1));
    let paramObj = [];
    for(var value of params.keys()) {
      paramObj[value] = params.get(value);
      apiUrl = updateURLParameter(apiUrl, value, params.get(value) )
    }

    apiUrl = updateURLParameter(apiUrl, 'page', paramObj['page'] || 1)


    this.wrapperDOM.classList.add('is-loading');

    const callback = (flag, response) => {
      this.wrapperDOM.classList.remove('is-loading');

      if(!flag){
        this.renderPlace.innerHTML = `<h5 class="h-ta-c h-mt-5">${response.data.message}</h5>`;
      }else{
        const renderedItems = this.renderCallback(response.data);
        this.renderPlace.innerHTML = "";
        renderedItems.forEach(item => this.renderPlace.appendChild(item))
				this.paginationData = response.data.pagination;
				this.advancedPagination ? this.renderPaginationAdvanced() : this.renderPagination()
      }
    }

    sendDataHandler({
      method: "GET",
      url: apiUrl,
      callback
    })
  }

  renderPagination() {
    // should render items by this search module

    let buttons = [];
    if(this.paginationData.hasPreviousPage) buttons.push(`<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.previousPage}" data-page='${this.paginationData.previousPage}'>Poprzednia strona</div>`)
    buttons.push(`<div class="btn btn--red h-ml-2 h-mr-2">${this.paginationData.currentPage}</div>`);
    if(this.paginationData.hasNextPage) buttons.push(`<div class="btn btn--red" title="Przejdź do strony ${this.paginationData.nextPage}" data-page='${this.paginationData.nextPage}'>Następna strona</div>`)

    this.paginationDOM.innerHTML = buttons.join('');


    const paginationItems = this.paginationDOM.querySelectorAll('[data-page]');
    paginationItems.forEach(item => item.addEventListener('click', () => this._makeApiCallTable(item.getAttribute('data-page'))))
	}
	
	renderPaginationAdvanced() {
		let buttons = []
		
    // if (this.paginationData.currentPage !== this.paginationData.lastPage) {
    //   buttons.push(`<div class="pagination-left"><p>Wybranych: <b>${this.paginationData.skip + this.paginationData.limit} z ${this.paginationData.total}</b></p></div>`)
    // } else {
      buttons.push(`<div class="pagination-left"><p>Łącznie: <b>${this.paginationData.total}</b></p></div>`)
    // }

    buttons.push(`<div class="pagination-right row h-ai-c">`)

    if (this.paginationData.hasPreviousPage) {
      buttons.push(`<span class="pagination__item cursor-pointer" data-page="${this.paginationData.previousPage}">
          <div class="icon-round icon-round--sm icon-round--hoverable h-ml-1">
            <img src="/client/images/arrow-left.svg" alt="" class="icon-round--sm__image">
          </div>
        </span>`)
    }

    if (this.paginationData.hasPreviousPage && this.paginationData.currentPage !== 1) {
      buttons.push(`<span class="pagination__item cursor-pointer" data-page="1">1</span>`)
    }

    if (this.paginationData.currentPage >= 5) {
      buttons.push(`<span class="pagination__item">...</span>`)
    }

    /** current - 2 */
    if (this.paginationData.currentPage >= 4) {
      buttons.push(
        `<span class="pagination__item cursor-pointer" data-page="${this.paginationData.currentPage - 2}">${this.paginationData.currentPage - 2}</span>`
      )
    }

    /** current - 1 */
    if (this.paginationData.currentPage >= 3) {
      buttons.push(
        `<span class="pagination__item cursor-pointer" data-page="${this.paginationData.currentPage - 1}">${this.paginationData.currentPage - 1}</span>`
      )
    }

    /** current */
    buttons.push(
      `<div class="h-ml-2 h-mr-2 border py-2 px-4 flex items-center justify-center">${this.paginationData.currentPage}</div>`
    )

    if (this.paginationData.currentPage <= this.paginationData.lastPage - 2) {
      buttons.push(
        `<span class="pagination__item cursor-pointer" data-page="${this.paginationData.currentPage + 1}">${this.paginationData.currentPage + 1}</span>`
      )
    }

    if (this.paginationData.currentPage <= this.paginationData.lastPage - 3) {
      buttons.push(
        `<span class="pagination__item cursor-pointer" data-page="${this.paginationData.currentPage + 2}">${this.paginationData.currentPage + 2}</span>`
      )
    }

    if (this.paginationData.currentPage <= this.paginationData.lastPage - 4) {
      buttons.push(`<span class="pagination__item">...</span>`)
    }

    if (this.paginationData.hasNextPage && this.paginationData.currentPage !== this.paginationData.lastPage) {
      buttons.push(`<span class="pagination__item cursor-pointer" data-page="${this.paginationData.lastPage}">${this.paginationData.lastPage}</span>`)
    }

    if (this.paginationData.hasNextPage && this.paginationData.currentPage !== this.paginationData.lastPage) {
      buttons.push(`<span class="pagination__item cursor-pointer" data-page="${this.paginationData.nextPage}">
          <div class="icon-round icon-round--sm icon-round--hoverable h-ml-1">
            <img src="/client/images/arrow-right.svg" alt="" class="icon-round--sm__image">
          </div>
      </span>`)
    }

    buttons.push(`<div class="ml-8">Strona: <b class="text-black">${this.paginationData.currentPage} z ${this.paginationData.lastPage}</b></div>`)

    buttons.push(`</div>`)

    if (this.paginationDOM){
			this.paginationDOM.innerHTML = buttons.join("")

			const paginationItems = this.paginationDOM.querySelectorAll("[data-page]")
			paginationItems.forEach(item => item.addEventListener("click", () => this._makeApiCallTable(item.getAttribute("data-page"))))
    }
  }
}