// /**
//  * Instantiate Tabs
//  * This module expects to every nav item have an attribute data-tab with value of index - data-set=1
//  * @param  {Node}       wrapper Dom class or dom node
//  * @param  {Object}     options Object holding optional parameters
//  * @return {undefined}
//  */
// export default class Tabs{
//   constructor( wrapper, options = {} ){
//     /** Check if wrapper is already dom element */
//     if(wrapper instanceof Element || wrapper instanceof HTMLDocument){
//       this.wrapperDOM = wrapper;
//     }else{
//       this.wrapperDOM = document.querySelector(wrapper);
//     }
//     if(!this.wrapperDOM) throw new Error('You must specify Tabs wrapper!');

//     /** Navigation tab items */
//     this.navItems = options.navItems || '.js-nav-tab__item';
//       /** Sort by data-tab attribute */
//       this.navItemsDOM = Array.from(this.wrapperDOM.querySelectorAll(this.navItems)).sort(this._sorter);

//     /** Tab items */
//     this.items = options.items || '.js-tab__item';
//     this.itemsDOM = this.wrapperDOM.querySelectorAll(this.items);

//     this.boundActivateEvent;
//     this._bindEvents();
//   }

//   _bindEvents() {
//     this.boundActivateEvent = ( event ) => this._activate( event );
//     this.navItemsDOM.forEach(item => item.addEventListener( 'click', this.boundActivateEvent ))
//   }

//   /** Call this method whenever tab item has been added */
//   /** Removes existing click event and adds new one */
//   recache() {
//     this.navItemsDOM = this.wrapperDOM.querySelectorAll(this.navItems);
//     this.navItemsDOM.forEach(item => {
//       item.removeEventListener('click', this.boundActivateEvent)
//       item.addEventListener('click', this.boundActivateEvent)
//     })
//   }

//   _activate( event ) {
//     const item = event.currentTarget;
//     let index = [...this.navItemsDOM].indexOf(item);

//     for( let i = 0; i < this.navItemsDOM.length; i++ ){
//       if(i !== index){
//         this.navItemsDOM[i].classList.remove('is-active');
//         this.itemsDOM[i].classList.remove('is-active');
//       }else{
//         this.navItemsDOM[i].classList.add('is-active');
//         this.itemsDOM[i].classList.add('is-active');
//       }
//     }
//   }

//   _sorter(a,b) {
//     if(a.dataset.tab < b.dataset.tab) return -1;
//     if(a.dataset.tab > b.dataset.tab) return 1;
//   }
// }

/**
 * Instantiate Tabs
 * This module expects to every nav item have an attribute data-tab with value of index - data-set=1
 * @param  {Node}       wrapper Dom class or dom node
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
export default class Tabs{
  constructor( wrapper, options = {} ){
    /** Check if wrapper is already dom element */
    if(wrapper instanceof Element || wrapper instanceof HTMLDocument){
      this.wrapperDOM = wrapper;
    }else{
      this.wrapperDOM = document.querySelector(wrapper);
    }
    if(!this.wrapperDOM) throw new Error('You must specify Tabs wrapper!');

    /** Navigation tab items */
    this.navItems = options.navItems || '.js-nav-tab__item';
    /** Sort by data-tab attribute */
    this.navItemsDOM = Array.from(this.wrapperDOM.querySelectorAll(this.navItems)).sort(this._sorter);

    /** Tab items */
    this.items = options.items || '.js-tab__item';
    this.itemsDOM = this.wrapperDOM.querySelectorAll(this.items);

    /** Callbacks */
    this.activationCallback = options.activationCallback;
    console.log('elo');
    this.boundActivateEvent;
    this._bindEvents();
  }

  _bindEvents() {
    this.boundActivateEvent = ( event ) => this._activate( event );
    this.navItemsDOM.forEach(item => item.addEventListener( 'click', this.boundActivateEvent ))
  }

  /** Call this method whenever tab item has been added */
  /** Removes existing click event and adds new one */
  recache() {
    this.navItemsDOM = this.wrapperDOM.querySelectorAll(this.navItems);
    this.navItemsDOM.forEach(item => {
      item.removeEventListener('click', this.boundActivateEvent)
      item.addEventListener('click', this.boundActivateEvent)
    })
  }

  _activate( event ) {
    const item = event.currentTarget;
    const dataTab = item.getAttribute('data-tab');

    this.navItemsDOM.forEach(i => i.classList.remove('is-active'));
    this.itemsDOM.forEach(i => i.classList.remove('is-active'));

    const itemsToActivate = this.wrapperDOM.querySelectorAll(`[data-tab="${dataTab}"]`);
    itemsToActivate.forEach(i => i.classList.add('is-active'));

    if(this.activationCallback) this.activationCallback(item, dataTab);
  }

  _sorter(a,b) {
    if(a.dataset.tab < b.dataset.tab) return -1;
    if(a.dataset.tab > b.dataset.tab) return 1;
  }
}