import "../styles/main.scss"

console.log('tutaj??123');
import "./vendors/polyfill"
import "./modules/choices"
import "./modules/flatpickr"
import "./modules/forms-actions"
import "./use-cases/paginated-content"
import "./use-cases/forms"
import "./use-cases/interacts"
import "./use-cases/notifications"
import "./use-cases/tabs"
import "./vue/index.js"