import Pagination from "../modules/pagination-new"
import PhraseSearch from "../modules/phraseSearch"
import moment from "moment"

try {
  const phraseSearch = new PhraseSearch(".js-search-wrapper")
} catch (err) {}

// admin
/* -------------------------------------------------------------------------- */
/*                             APPLICANTS TABLE                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      let donor = item.form && item.form.darczynca == "Tak" ? "" : "is-inactive"
      let avatar = "client/images/man-user.svg"
      let imageClass = ""
      let status = "W trakcie weryfikacji"
      item.statusHistory && item.statusHistory && item.statusHistory.length > 0 ? (status = item.statusHistory[item.statusHistory.length - 1].title) : null
      let archive = item.archive ? "Od arch." : "Arch."

      if (typeof item._avatar == "object" && item._avatar.length > 0) avatar = item._avatar[0].filepath
      if (typeof item._avatar == "object" && item._avatar.length > 0) imageClass = "is-fit"

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col table-col--3xs h-ai-c">
          <div class="icon-round-wrapper icon-round-wrapper--xs h-ja-c">
            <img src="/${avatar}" alt="${
        item.form && item.form.imieNazwiskoPotrzebujacego
      }" onerror="this.onerror=null;this.src='/client/images/man-user.svg'" class="icon-round icon-round--xs js-avatar ${imageClass}">
          </div>
        </div>
        <div class="table-col h-flex-1 h-ml-1 h-ai-c">${item.fullname || ""}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${item.date ? moment(item.date).format("DD.MM.YYYY") : ""}</div>
        <div class="table-col table-col--sm h-ml-1 h-ai-c">${status}</div>
        <div class="table-col table-col--3xs h-ml-1 h-ai-c"><img src="/client/images/check.svg" alt="" class="icon icon--xs ${donor}"></div>
        <div class="table-col table-col--2xl h-ml-1 h-ai-c">
          <div class="h-flex h-ai-s h-fw">
            <div class="h-flex-1">
              <a href="/admin/wnioskodawcy/${item._id}" class="btn btn--red h-fw">Edytuj</a>
            </div>
            <div class="h-ml-2 col-auto">
              <div class="btn btn--red h-fw" onclick="archiveApplicant('${item._id}')">${archive}</div>
            </div>
            <div class="h-ml-2 col-auto">
              <div class="btn btn--red h-fw" onclick="removeUser('${item._id}')">Usuń</div>
            </div>
          </div>
        </div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  const paginated = new Pagination(".js-table-applicants", {
    fetchApi: "/api/applicants",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
    advancedPagination: true,
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                PROTEGES TABLE                              */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      let avatar = "/client/images/man-user.svg"
      let imageClass = ""
      let donor = item.form && item.form.darczynca == "Tak" ? "" : "is-inactive"

      if (typeof item._avatar == "object" && item._avatar.length > 0) avatar = item._avatar[0].filepath
      if (typeof item._avatar == "object" && item._avatar.length > 0) imageClass = "is-fit"
      let archive = item.archive ? "Od arch." : "Arch."

      let money = item.collectedMoneyP24 + item.collectedMoneyPekao + item.collectedMoneyOnePercentage + item.collectedMoneyOther
      let spentMoney = 0
      let restMoney = money
      if (item.spentMoney) {
        spentMoney = item.spentMoney + item.totalCustomCharges
      }
      restMoney = money - spentMoney

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col table-col--3xs h-ai-c">${item.serialNumber || "Brak"}</div>
        <div class="table-col table-col--3xs h-ai-c h-ml-1">
          <div class="icon-round-wrapper icon-round-wrapper--xs h-ja-c">
            <img src="/${avatar}" alt="${
        item.form && item.form.imieNazwiskoPotrzebujacego
      }" onerror="this.onerror=null;this.src='/client/images/man-user.svg'" class="icon-round icon-round--xs js-avatar ${imageClass}">
          </div>
        </div>
        <div class="table-col h-flex-1 h-ml-1 h-ai-c">${item.fullname}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${item.promotionDate ? moment(item.promotionDate).format("DD.MM.YYYY") : ""}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${money ? money.toFixed(2) : 0}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${spentMoney ? spentMoney.toFixed(2) : 0}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${restMoney ? restMoney.toFixed(2) : 0}</div>
        <div class="table-col table-col--xs h-ml-1 h-ai-c">${item.remainingMoneyOnePercentage ? item.remainingMoneyOnePercentage.toFixed(2) : 0}</div>
        <div class="table-col table-col--3xs h-ml-1 h-ai-c"><img src="/client/images/check.svg" alt="" class="icon icon--xs ${donor}"></div>
        <div class="table-col table-col--2xl h-ml-1 h-ai-c">
					<div class="h-flex col-auto">
						<a href="/admin/podopieczni/${item._id}?protege=${item._id}" class="btn btn--red h-fw">Edytuj</a>
					</div>
					<div class="h-flex h-ai-s h-fw h-ml-2">
						<div onclick="archiveApplicant('${item._id}')" class="btn btn--red h-fw">${archive}</div>
					</div>
          <div class="h-ml-2 col-auto">
            <div class="btn btn--red h-fw" onclick="removeUser('${item._id}')">Usuń</div>
          </div>
        </div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-proteges", {
    fetchApi: "/api/admin/proteges",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
    advancedPagination: true,
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                              VOLUNTEERS TABLE                              */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col h-flex-1 h-ai-c">${item.fullname}</div>
        <div class="table-col table-col--xl h-ml-1 h-ai-c">${item.email}</div>
        <div class="table-col table-col--sm h-ml-1 h-ai-c">${item.phone}</div>
        <div class="table-col table-col--sm h-ml-1 h-ai-c">${item.date ? moment(item.date).format("DD.MM.YYYY") : ""}</div>
        <div class="table-col table-col--lg h-ml-1 h-ai-c">
          <div class="h-flex h-ai-s h-fw">
            <div class="h-flex-1">
              <a href="/admin/wolontariusz/${item._id}" class="btn btn--red h-fw">Edytuj</a>
            </div>
            <div class="h-flex-1">
						<div data-id="${item._id}" data-href="/api/user/${item._id}" class="btn btn--red h-fw h-ml-2" onclick="deleteSubmit(event)">Usuń</div>
            </div>
          </div>
        </div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-volunteers", {
    fetchApi: "/api/volunteers",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                             DONATIONS TABLE                               */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const summedDonationsDOM = document.querySelector(".js-summed-donations")
    if (window.location.href.indexOf("tab=portal") != -1) {
      summedDonationsDOM.innerHTML = data.amountPortal
    } else if (window.location.href.indexOf("tab=summary") != -1) {
      summedDonationsDOM.innerHTML = data.amountPortal + data.amount
    } else {
      summedDonationsDOM.innerHTML = data.amount.toFixed(2)
    }

    const render = (item) => {
      let paymentType = ""
      if (item.paymentType) {
        switch (item.paymentType) {
          case "dotpay":
            paymentType = '<img src="/client/images/dotpay_logo.svg" alt="Dotpay" class="icon icon--sm h-ml-2">'
            break
          case "paypal":
            paymentType = '<img src="/client/images/paypal.svg" alt="PayPal" class="icon icon--sm h-ml-2">'
            break
          case "przelewy24":
            paymentType = '<img src="/client/images/przelewy24.svg" alt="Przelewy24" class="icon icon--sm h-ml-2">'
            break
          case "traditional":
            paymentType = '<img src="/client/images/traditional-payment.png" alt="Tradycyjny przelew" class="icon icon--sm h-ml-2">'
            break
          case "sms":
            paymentType = '<img src="/client/images/traditional-payment.png" alt="SMS" class="icon icon--sm h-ml-2">'
            break
          case "1%":
            paymentType = '<img src="/client/images/icons/1procent.svg" alt="SMS" class="icon icon--sm h-ml-2">'
            break
          case "pko":
            paymentType = '<img src="/client/images/pekao.png" alt="Pekao" class="icon icon--sm h-ml-2">'
            break
          case "inne":
            paymentType = "<span>Inne</span>"
            break
        }
      }
      let amount = item.amount ? item.amount.toFixed(2) : ""

      if (window.location.href.indexOf("tab=portal") != -1) {
        amount = item.amountPortal ? item.amountPortal.toFixed(2) : ""
      }
      if (window.location.href.indexOf("tab=summary") != -1) {
        amount = item.amount ? item.amount : 0
        amount += item.amountPortal ? item.amountPortal : 0
        amount = amount.toFixed(2)
      }

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
				<div class="table-col h-flex-1 h-ai-c">${item.fullname ? item.fullname : ""}</div>
				<div class="table-col h-flex-1 h-ml-1 h-ai-c">${item.email ? item.email : ""}</div>
				<div class="table-col h-flex-1 h-ml-1 h-m h-ai-c">
					${
            item._protege && item._protege[0]
              ? "<a href='/admin/podopieczni/" + item._protege[0]._id + "?protege=" + item._protege[0]._id + "'>" + item._protege[0].fullname + "</a>"
              : ""
          }
					${item._project && item._project[0] ? "<a href='/admin/projekty/" + item._project[0].slug + "'>" + item._project[0].title + "</a>" : ""}
				</div>
				<div class="table-col table-col--md h-ml-1 h-ai-c h-of-h">${item.purpose ? item.purpose : ""}</div>
				<div class="table-col table-col--sm h-ml-1 h-ai-c h-jc-sb">${amount} ${paymentType}</div>
				<div class="table-col table-col--xs h-ml-1 h-ai-c">${moment(item.date).format("DD.MM.YYYY")}</div>
				<div class="table-col table-col--md h-ml-1 h-ai-c">
					<div class="h-flex h-ai-s h-fw">
						<div class="h-flex-1 h-flex">
							<a href="/admin/darczyncy/${item._id}" class="btn btn--red h-fw">Zobacz</a>
							<div onclick="archiveDonation('${item._id}')" class="btn h-ml-1 btn--red h-fw">Usuń</div>
						</div>
					</div>
				</div>
			`
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  const table = document.querySelector(".js-table-donations")
  const href = table.getAttribute("data-href")

  const paginated = new Pagination(".js-table-donations", {
    fetchApi: href || "/api/donations",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
    advancedPagination: true,
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                             USER MESSAGES TABLE                            */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-4 h-ai-c">${moment(item.date).format("DD.MM.YYYY HH:mm")}</div>
        <div class="table-col col-lg-14 h-ml-2 h-ai-c"><a href="/wiadomosci/${item._id}">${item.title}</a></div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-messages", {
    fetchApi: "/api/messages",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                            ADMIN MESSAGES TABLE                            */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      let person = item._author[0] && item._author[0].email
      let personId = item._author[0] && item._author[0]._id
      let read = `<span class="green" style="position: absolute;top: 3px;font-size: 12px;font-weight: 700;">Nowa wiadomość</span>`
      if (item.read == true) read = ""

      if (window.location.href.indexOf("sent") > -1) {
        if (item._recipient.length >= 1) {
          person = item._recipient[0].email
        } else {
          person = "Użytkownik już nie istnieje"
        }
      }

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-3 h-ai-c">${moment(item.date).format("DD.MM.YYYY HH:mm")}</div>
        <div class="table-col col-lg-7 h-ml-2 h-ai-c h-pr">${read} <a href="/admin/wiadomosci/${item._id}">${item.title}</a></div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c"><a href="/admin/wiadomosci/korespondent/${personId}">${person}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c"><a href="/admin/wiadomosci/napisz-wiadomosc?recipient=${person}&replyTo=${
        item._id
      }&topic=W odpowiedzi na: '${item.title}'"
        class="btn btn--red h-fw">Odpowiedz</a></div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-messages-admin", {
    fetchApi: "/api/messages",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                               ILLATIONS TABLE                              */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const totalDOM = document.querySelector(".js-total-money")
    if (totalDOM)
      totalDOM.innerHTML = `
			<p class="mt-8 font-bold">Kwota wnioskowana: ${data.amount} zł</p>
			<p class="font-bold">Kwota przyznana: ${data.amountGranted} zł</p>
		`

    const render = (item) => {
      let isAdmin = window.location.href.indexOf("/admin") > -1
      let link = `/wnioski/${item._id}`
      let showMoreColumns = window.location.href.indexOf("/admin") > -1
      if (showMoreColumns) link = `/admin/wnioski/${item._id}`

      let status = "W trakcie rozpatrywania"
      let statusColor = "border-l-failed"
      if (item.status === 1) status = "Rozpatrzony"
      if (item.status === 1) statusColor = "border-l-success"
      if (item.status === 2) status = "Zrealizowany"
      if (item.status === 2) statusColor = "border-l-blue"

      let onePercentageRefunded = ""
      if (item.refundedWithOnePercentageMoney >= 0) onePercentageRefunded = item.refundedWithOnePercentageMoney + " zł"

      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-lg-3 h-ai-c ${statusColor}">${moment(item.date).format("DD.MM.YYYY HH:mm")}</div>
        <div class="table-col col-lg-3 h-ml-2 h-ai-c">
          <a href="/admin/wnioski?protege=${item._user[0] ? item._user[0]._id : ""}" class="underline">
            ${item._user[0] ? item._user[0].serialNumber : "usunięty"}.${item._user[0] ? item._user[0].fullname : "usunięty"}
          </a>
        </div>
        <div class="table-col ${showMoreColumns ? "col-lg-2" : "col-lg-3"} h-ml-2 h-ai-c">${item.wantedMoney ? Number(item.wantedMoney.toFixed(2)) : 0} zł</div>
        <div class="table-col ${showMoreColumns ? "col-lg-2" : "col-lg-3"} h-ml-2 h-ai-c">${
        item.grantedMoney ? Number(item.grantedMoney.toFixed(2)) : 0
      } zł</div>
        <div class="table-col ${showMoreColumns ? "col-lg-2 h-ai-c" : "hidden"} h-ml-2 ">${onePercentageRefunded}</div>
        <div class="table-col col-lg-4 h-ml-2 h-ai-c">${status}</div>
				<div class="table-col col-lg-4 h-ml-2 h-ai-c">
					<a href="${link}" class="btn btn--red h-fw">Szczegóły</a>
					${
            isAdmin
              ? "<div data-id=" +
                item._id +
                " data-href='/api/admin/illation/" +
                item._id +
                "' class='btn btn--red h-fw h-ml-2' onclick='deleteSubmit(event)'>Usuń</div>"
              : ""
          }
				</div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  new Pagination(".js-table-illations", {
    fetchApi: "/api/illations",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                           USER DATA HISTORY TABLE                          */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      console.log(item)
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
        <div class="table-col col-6 h-ai-c">${moment(item.date).format("DD.MM.YYYY HH:mm")}</div>
        <div class="table-col col-6 h-ml-2 h-ai-c"><a href="/admin/podopieczni/${item._user}/historia-zmian/${
        item._id
      }" class="btn btn--red h-fw">Szczegóły</a></div>
      `
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  const href = document.querySelector(".js-table-user-data-history").getAttribute("data-href")
  new Pagination(".js-table-user-data-history", {
    fetchApi: href,
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 UNITS TABLE                                */
/* -------------------------------------------------------------------------- */
try {
  const callback = (data) => {
    const render = (item) => {
      const row = document.createElement("div")
      row.classList = "table-row"
      row.id = item._id
      row.innerHTML = `
				<div class="table-col col-lg-1 h-ai-c">${item.group}</div>
				<div class="table-col col-lg-6 h-ml-2 h-ai-c">${item.title}</div>
				<div class="table-col col-lg-5 h-ml-2 h-ai-c">${item.email}</div>
				<div class="table-col col-lg-3 h-ml-2 h-ai-c">${item.voivodeship}</div>
        <div class="table-col col-lg-6 h-ml-2 h-flex h-ai-c">
          <div class="col-md-6">
            <a href="/admin/urzedy-skarbowe/${item._id}" class="btn btn--red h-fw">Edytuj</a>
          </div>
          <div class="col-md-6 h-ml-2 h-flex-1">
            <div class="btn btn--red h-fw" onclick="deleteSubmit(event)" data-id="${item._id}" data-href="${"/api/unit/" + item._id}">Usuń</div>
          </div>
        </div>`
      return row
    }

    const renderedData = data.items.map((item) => {
      return render(item)
    })
    return renderedData
  }

  const users = new Pagination(".js-table-units", {
    fetchApi: "/api/units",
    renderPlace: ".js-table-content",
    paginationDOM: ".js-pagination",
    renderCallback: callback,
    advancedPagination: true,
  })
} catch (err) {}
