import sendDataHandler from "./sendDataHandler"
import { StatusModal, UploadModal, ConfirmModal } from "./modal"
import forms from "./forms"
import Accordion from "./accordion"
import Tabs from "./tabs"
import moment from "moment"
import FormDataFetcher from "./FormDataFetcher"
import { cleanUpElements, renderErrorsToForm } from "../helpers/dom"
import fetchWrapper from "../modules/fetch-wrapper"
import Notify from "../modules/notify"

let statusModal
let uploadModal
let confirmModal
try {
  statusModal = new StatusModal(".js-modal-container-status")
  uploadModal = new UploadModal(".js-modal-container-upload")
  confirmModal = new ConfirmModal(".js-modal-container-confirm")
  window.uploadModal = uploadModal
} catch (e) {
  console.log(e)
}

/**  Default delete callback */
const defaultDeleteCallback = (flag, response, id) => {
  console.log(flag, response, id)
  // if (!flag) {
  //   confirmModal.injectHTML({ title: `Błąd`, content: response.error ? response.error.message : response.message })
  //   confirmModal.setFailed()
  // } else {
  // 	if(id) document.getElementById(id).remove();
  //   confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
  // 	confirmModal.setSuccess()
  // }
  if (!flag) {
    confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
    confirmModal.setFailed()
  } else {
    confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
    confirmModal.setSuccess()
    document.getElementById(id).remove()
  }
}

const defaultCallback = (flag, response) => {
  if (!flag) {
    statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
    statusModal.toggleModal()
  } else {
    statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
    statusModal.toggleModal()
  }
}

/**
 * Create Applicant Form
 */
try {
  const createApplicant = document.querySelector(".js-submit-create-applicant")

  createApplicant.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(response, "tutej")
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        // statusModal.redirect({ url: '/admin/wnioskodawcy', timeout: 2000 });
      }

      if (response.data.failedFields) {
        response.data.failedFields.forEach((field) => {
          forms.checkForEmptyInput(field)
        })
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-applicant"))

    let documents = []

    for (var pair of data.entries()) {
      if (pair[0].includes("[]")) {
        documents.push(pair[1])
      }
    }
    data.append("documents", documents.join(","))

    sendDataHandler({
      method: "post",
      url: "/admin/wnioskodawca",
      data,
      callback,
    })
  })
} catch (err) {}

/**
 * Create Protege Form
 */
try {
  const createProtege = document.querySelector(".js-submit-create-protege")

  createProtege.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: "Podopieczny został dodany." })
        statusModal.toggleModal()
        // statusModal.redirect({ url: '/admin/wnioskodawcy', timeout: 2000 });
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-protege"))

    let documents = []

    for (var pair of data.entries()) {
      if (pair[0].includes("documents[]")) {
        documents.push(pair[1])
      }
      if (pair[0].includes("gallery[]")) {
        gallery.push(pair[1])
      }
    }

    data.append("documents", documents.join(","))
    data.append("gallery", documents.join(","))

    sendDataHandler({
      method: "post",
      url: "/api/protege",
      data,
      callback,
    })
  })
} catch (err) {}

/**
 * Create Volunteer Form
 */
try {
  const createVolunteer = document.querySelector(".js-submit-create-volunteer")

  createVolunteer.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        // TODO: this should happen only after closing modal
        statusModal.redirect({ url: "/admin/wolontariusz", timeout: 2000 })
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-volunteer"))

    sendDataHandler({
      method: "post",
      url: "/api/volunteer",
      data,
      callback,
    })
  })
} catch (err) {}

/**
 * Attach remove document
 */
try {
  const removableDocumentsDOM = document.querySelectorAll(".js-removable-document")
  removableDocumentsDOM.forEach((doc) =>
    doc.addEventListener("click", (event) => forms.removeDocument("doc_" + event.currentTarget.getAttribute("data-remove")))
  )
} catch (err) {}

/**
 * Adding Files
 */
try {
  const addFiles = document.querySelector(".js-add-files")
  const documentsDOM = document.querySelector(".js-documents")

  const callback = () => {
    uploadModal.toggleModal()
    console.log(uploadModal.selectedImage)

    const div = document.createElement("div")
    div.classList = "h-flex h-ai-c h-mt-2"
    div.id = "doc_" + uploadModal.selectedImage._id
    div.innerHTML = `
      <a href="/${uploadModal.selectedImage.filepath}" download><img src="/client/images/download.svg" alt="Pobierz" class="icon icon--sm"></a>
      <a href="/${uploadModal.selectedImage.filepath}" target="_blank" class="h-ml-2 link">${uploadModal.selectedImage.filename}</a>
      <img src="/client/images/bin.svg" alt="Kosz" class="icon icon--xs h-ml-2 icon--hover js-removable-document" data-remove="${uploadModal.selectedImage._id}">
      <input type="hidden" name="documents[]" value="${uploadModal.selectedImage._id}">
    `

    documentsDOM.appendChild(div)

    const removableDocmentsDOM = document.querySelectorAll(".js-removable-document")

    removableDocmentsDOM.forEach((doc) =>
      doc.addEventListener("click", (event) => forms.removeDocument("doc_" + event.currentTarget.getAttribute("data-remove")))
    )
  }

  addFiles.addEventListener("click", () => {
    uploadModal.toggleModal({ callbackOnModalClose: callback })
  })
} catch (err) {}

/**
 * Adding Gallery
 */
try {
  const addFiles = document.querySelector(".js-add-to-gallery")
  const documentsDOM = document.querySelector(".js-gallery-photo")

  const callback = () => {
    uploadModal.toggleModal()
    console.log(uploadModal.selectedImage)

    const div = document.createElement("div")
    div.classList = "col-md-3 col-padded--md h-mt-2"
    div.id = "gallery_" + uploadModal.selectedImage._id
    div.innerHTML = `
      <div class="gallery-item">
        <div class="gallery-item__delete js-removable-item" data-remove="${uploadModal.selectedImage._id}">
          <img src="/client/images/bin.svg" alt="Kosz" class="icon icon--xs">
        </div>
        <img src="/${uploadModal.selectedImage.filepath}" alt="Zdjęcie" class="gallery-item__image">
        <input type="hidden" name="gallery[]" value="${uploadModal.selectedImage._id}">
      </div>
    `

    documentsDOM.appendChild(div)

    const removableItems = document.querySelectorAll(".js-removable-item")

    removableItems.forEach((doc) =>
      doc.addEventListener("click", (event) => forms.removeDocument("gallery_" + event.currentTarget.getAttribute("data-remove")))
    )
  }

  addFiles.addEventListener("click", () => {
    uploadModal.toggleModal({ callbackOnModalClose: callback })
  })
} catch (err) {}

/**
 * Attach remove gallery
 */
try {
  const removableGalleryItemsDOM = document.querySelectorAll(".js-removable-item")
  removableGalleryItemsDOM.forEach((item) =>
    item.addEventListener("click", (event) => forms.removeDocument("gallery_" + event.currentTarget.getAttribute("data-remove")))
  )
} catch (err) {}

/**
 * Change Avatar;
 */
try {
  const changeAvatarDOM = document.querySelector(".js-change-avatar")
  const avatarDOM = document.querySelector(".js-avatar")
  const avatarInputDOM = document.querySelector('[name="avatar"]')

  const callback = () => {
    uploadModal.toggleModal()
    console.log(uploadModal.selectedImage)

    avatarDOM.src = "/" + uploadModal.selectedImage.filepath
    avatarDOM.classList.add("is-fit")

    avatarInputDOM.value = uploadModal.selectedImage._id
  }

  changeAvatarDOM.addEventListener("click", () => {
    uploadModal.toggleModal({ callbackOnModalClose: callback })
  })
} catch (err) {}

/**
 * Set file for input
 */
try {
  const btnDOM = document.querySelectorAll(".js-set-file")

  const callback = (input, place) => {
    console.log(uploadModal.selectedImage, input)
    uploadModal.toggleModal()
    input.value = uploadModal.selectedImage._id
    place.innerHTML = uploadModal.selectedImage.filename
  }
  btnDOM.forEach((btn) => {
    const inputDOM = document.querySelector(btn.getAttribute("data-input"))
    const placeDOM = document.querySelector(btn.getAttribute("data-place"))
    btn.addEventListener("click", () => {
      uploadModal.toggleModal({ callbackOnModalClose: () => callback(inputDOM, placeDOM) })
    })
  })
} catch (err) {}

/**
 * Saving enabled editable fields for applicant-detail
 */
try {
  const saveChanges = document.querySelectorAll(".js-save-changes")

  saveChanges.forEach((btn) =>
    btn.addEventListener("click", () => {
      console.log("click")
      let formDOM = document.querySelector(btn.getAttribute("data-form"))

      const callback = (flag, response) => {
        console.log(response)
        if (!flag) {
          statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
          statusModal.toggleModal()
        } else {
          statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
          statusModal.toggleModal()
          forms.disableEditing(btn, formDOM)
        }
      }

      const data = new FormData(formDOM)
      const id = btn.getAttribute("data-id")

      let documents = []

      for (var pair of data.entries()) {
        if (pair[0].includes("[]")) {
          documents.push(pair[1])
        }
      }

      if (data.has("documents[]") || btn.getAttribute("data-documents")) data.append("documents", documents.join(","))

      sendDataHandler({
        method: "put",
        url: `/api/applicant?id=${id}`,
        data,
        callback,
      })
    })
  )
} catch (err) {}

try {
  const btns = document.querySelectorAll(".js-consider-applicant-btn")
  btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      const id = btn.getAttribute("data-id")
      const action = btn.getAttribute("data-action")

      const confirmCallback = () => {
        const data = new FormData()

        data.append("id", id)
        data.append("action", action)

        let serialNumber = document.querySelector('input[name="serialNumber"]').value
        data.append("serialNumber", serialNumber)

        confirmModal.setLoading()

        sendDataHandler({
          method: "put",
          url: "/api/applicant/consider",
          data,
          callback: callback,
        })
      }

      const callback = (flag, response) => {
        confirmModal.toggleModal()

        if (!response.data.success) {
          statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
          statusModal.toggleModal()
        } else {
          statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
          statusModal.toggleModal()
          // if (action == 1) setTimeout(() => (window.location.href = "/admin/wnioskodawcy"), 1500)
        }
      }

      if (action == 0) {
        confirmModal.injectHTML({
          title: `Potwierdź`,
          content: `Zmieniasz status na negatywny. Czy na pewno kontynuować? <input type="hidden" name="serialNumber">"`,
          confirmCallback,
        })
      } else {
        confirmModal.injectHTML({
          title: `Potwierdź`,
          content: `Podaj numer wsparcia dla użytkownika
        <div class="form-text h-flex-1 h-mt-1rem" data-edit="">
          <input type="number" name="serialNumber">
        </div>
        `,
          confirmCallback,
        })
      }

      confirmModal.toggleModal()
    })
  })
} catch (err) {}

/**
 * Archive or unarchive applicant
 */
try {
  window.archiveApplicant = (id) => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        document.getElementById(id).remove()
      }
    }
    const data = new FormData()
    data.append("id", id)

    sendDataHandler({
      method: "put",
      url: "/api/user/archive",
      data,
      callback,
    })
  }
} catch (err) {}
/**
 * Set status for applicant-detail
 */
try {
  const btn = document.querySelector(".js-send-status-btn")
  const form = document.querySelector(".js-send-status-form")
  btn.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(flag, response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const id = btn.getAttribute("data-id")
    const data = new FormData(form)

    data.append("id", id)

    sendDataHandler({
      method: "put",
      url: "/api/applicant/status",
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                 DELETE User                                */
/* -------------------------------------------------------------------------- */
window.removeUser = (id) => {
  const callback = (flag, response) => {
    if (!flag) {
      confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
      confirmModal.setFailed()
    } else {
      confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
      confirmModal.setSuccess()
      document.getElementById(id).remove()
    }
  }

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "delete",
      url: `/api/user/${id}`,
      callback: callback,
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno usunąć użytkownika?", confirmCallback })
  confirmModal.toggleModal()
}

/**
 *
 */
try {
  let checkboxWrappers = document.querySelectorAll(".js-toggle-checkboxes")
  checkboxWrappers.forEach((wrapper) => forms.checkToggleCheckboxes(wrapper))
} catch (err) {}

/**
 * Delete checked statuses
 */
try {
  const deleteBtn = document.querySelector(".js-remove-checked")

  deleteBtn.addEventListener("click", () => {
    const formDOM = document.querySelector(deleteBtn.getAttribute("data-form"))

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()

        if (response.data.all) {
          let allRows = document.querySelectorAll("[data-status-row]")
          allRows.forEach((status) => {
            status.remove()
          })
        } else if (response.data.items) {
          response.data.items.forEach((status) => {
            document.getElementById(status).remove()
          })
        }
      }
    }

    // const data = new FormData(formDOM);

    let deleteStatus = []

    let data = new FormDataFetcher(formDOM)
    data.append("id", deleteBtn.getAttribute("data-id"))

    sendDataHandler({
      method: "delete",
      url: "/api/applicant/status",
      data: data.data,
      callback,
    })
  })
} catch (err) {}

/**
 * Send Message
 */

try {
  const sendMessage = document.querySelector(".js-send-message")
  sendMessage.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(document.querySelector(".js-form-message"))

    sendDataHandler({
      method: "post",
      url: "/api/message",
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                               CREATE PROJECT                               */
/* -------------------------------------------------------------------------- */
try {
  const createProject = document.querySelector(".js-submit-create-project")
  createProject.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        statusModal.redirect({ url: "/admin/projekty", timeout: 2000 })
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-project"))

    sendDataHandler({
      method: "post",
      url: "/api/project",
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                        ADD PROTEGE INTO PROJECT LIST                       */
/* -------------------------------------------------------------------------- */
try {
  const addToList = document.querySelector(".js-add-to-list")
  addToList.addEventListener("click", () => {
    const selectDOM = document.querySelector(addToList.getAttribute("data-select"))
    const id = addToList.getAttribute("data-id")
    const selectedValue = selectDOM.querySelector("select").value
    const tableDOM = document.querySelector(addToList.getAttribute("data-table"))

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        const rowDOM = renderTableRow(response.data.person)
        tableDOM.appendChild(rowDOM)
        forms.checkToggleCheckboxes(".js-toggle-checkboxes")
      }
    }

    function renderTableRow(protege) {
      const rowDOM = document.createElement("div")
      rowDOM.className = "table-row"
      rowDOM.id = protege._id

      let avatar = protege._avatar ? "/" + protege._avatar.filepath : "/client/images/man-user.svg"
      let imageClass = protege._avatar ? "is-fit" : ""

      const html = `
          <div class="table-col col-lg-1 h-ai-c">${protege.serialNumber}</div>
          <div class="table-col col-lg-2 h-ml-2 h-ai-c">
            <div class="icon-round-wrapper icon-round-wrapper--xs h-ja-c">
              <img src="${avatar}" class="icon-round icon-round--xs ${imageClass}">
            </div>
          </div>
          <div class="table-col col-lg-9 h-ml-2 h-ai-c h-flex h-jc-sb">${protege.fullname}</div>
          <div class="table-col col-lg-3 h-ml-2 h-ai-c h-flex h-jc-sb">0 zł</div>
          <div class="table-col--pad-lg col-lg-3 h-ml-2 h-jc-e">
            <div class="form-group form-checkbox">
              <input type="checkbox" id="checkbox-${protege._id}" name="id[]" value="${protege._id}">
              <label for="checkbox-${protege._id}"></label>
            </div>
          </div>
        `

      rowDOM.innerHTML = html
      return rowDOM
    }

    const data = new FormData()
    data.append("id", selectedValue)
    data.append("projectId", id)

    sendDataHandler({
      method: "put",
      url: `/api/user/assign-project`,
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                               UPDATE PROJECT                               */
/* -------------------------------------------------------------------------- */
/**
 * Saving enabled editable fields for applicant-detail
 */
try {
  const saveChanges = document.querySelectorAll(".js-save-project-changes")

  saveChanges.forEach((btn) =>
    btn.addEventListener("click", () => {
      let formDOM = document.querySelector(btn.getAttribute("data-form"))

      const callback = (flag, response) => {
        console.log(response)
        if (!flag) {
          statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
          statusModal.toggleModal()
        } else {
          statusModal.injectHTML({ title: `Sukces`, content: "Zmiany zostały zapisane" })
          statusModal.toggleModal()
          forms.disableEditing(btn, formDOM)
        }
      }

      const data = new FormData(formDOM)
      const id = btn.getAttribute("data-id")

      let documents = []

      for (var pair of data.entries()) {
        if (pair[0].includes("[]")) {
          documents.push(pair[1])
        }
      }

      if (data.has("documents[]") || btn.getAttribute("data-documents")) data.append("documents", documents.join(","))

      sendDataHandler({
        method: "put",
        url: `/api/project/${id}`,
        data,
        callback,
      })
    })
  )
} catch (err) {}

/**
 * Saving enabled editable fields for protege-detail
 */
try {
  const saveChanges = document.querySelectorAll(".js-save-changes-protege")

  saveChanges.forEach((btn) =>
    btn.addEventListener("click", () => {
      let formDOM = document.querySelector(btn.getAttribute("data-form"))
      const hasToRefresh = btn.getAttribute("data-refresh") ? true : false

      const callback = (flag, response) => {
        if (!flag) {
          statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
          statusModal.toggleModal()
        } else {
          statusModal.injectHTML({ title: `Sukces`, content: "Zmiany zostały zapisane" })
          statusModal.toggleModal()
          forms.disableEditing(btn, formDOM)
          if (hasToRefresh) window.location.reload(false)
        }
      }

      const data = new FormData(formDOM)
      const id = btn.getAttribute("data-id")

      // let documents = []
      // let gallery = []

      // for (var pair of data.entries()) {
      //   if (pair[0].includes("documents[]")) {
      //     documents.push(pair[1])
      //   }
      //   if (pair[0].includes("gallery[]")) {
      //     gallery.push(pair[1])
      //   }
      // }

      // if (data.has("documents[]") || btn.getAttribute("data-documents")) data.append("documents", documents.join(","))
      // if (data.has("gallery[]") || btn.getAttribute("data-gallery")) data.append("gallery", gallery.join(","))

      sendDataHandler({
        method: "put",
        url: `/api/protege?id=${id}`,
        data,
        callback,
      })
    })
  )
} catch (err) {}

/**
 * Data actualisation for *self*
 */
try {
  const btn = document.querySelector(".js-data-actualisation-protege")

  btn.addEventListener("click", () => {
    let formDOM = document.querySelector(btn.getAttribute("data-form"))

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: "Zmiany zostały zapisane" })
        statusModal.toggleModal()
        forms.disableEditing(btn, formDOM)
      }
    }

    const data = new FormData(formDOM)
    const id = btn.getAttribute("data-id")

    sendDataHandler({
      method: "put",
      url: `/api/self/data-actualisation`,
      data,
      callback,
    })
  })
} catch (err) {}

/**
 * Cache protege form data
 */
try {
  const btn = document.querySelector(".js-cache-protege")
  btn.addEventListener("click", () => {
    const id = btn.getAttribute("data-id")

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    sendDataHandler({
      method: "put",
      url: `/api/protege/cache?id=${id}`,
      callback,
    })
  })
} catch (e) {}

/**
 * Delete checked Proteges in project-detail
 */
try {
  const deleteBtn = document.querySelector(".js-remove-checked1")

  // deleteBtn.addEventListener("click", () => {
  //   const formDOM = document.querySelector(deleteBtn.getAttribute("data-form"))
  //   const id = deleteBtn.getAttribute("data-id")

  //   const callback = (flag, response) => {
  //     if (!flag) {
  //       statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
  //       statusModal.toggleModal()
  //     } else {
  //       statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
  //       statusModal.toggleModal()
  //       forms.uncheckMainToggleCheckbox(formDOM)

  //       if (response.data.deletedItems) {
  //         if (Array.isArray(response.data.deletedItems)) {
  //           response.data.deletedItems.forEach(item => {
  //             document.getElementById(item).remove()
  //           })
  //         } else {
  //           document.getElementById(response.data.deletedItems).remove()
  //         }
  //       } else if (response.data.deletedAll) {
  //         let allRows = document.querySelectorAll("[data-row]")
  //         allRows.forEach(item => {
  //           item.remove()
  //         })
  //       }
  //     }
  //   }

  //   const data = new FormData(formDOM)

  //   let deleteItem = []

  //   for (var pair of data.entries()) {
  //     if (pair[0].includes("[]")) {
  //       deleteItem.push(pair[1])
  //     }
  //   }

  //   if (deleteItem.length <= 0) {
  //     return
  //   }

  //   data.append("deleteItem", deleteItem.join(","))

  //   data.append("projectId", id)
  //   data.append("action", "removeAssignToProject")

  //   sendDataHandler({
  //     method: "delete",
  //     url: `/api/protege-assign`,
  //     data,
  //     callback
  //   })
  // })
  deleteBtn.addEventListener("click", () => {
    const formDOM = document.querySelector(deleteBtn.getAttribute("data-form"))
    const projectId = deleteBtn.getAttribute("data-id")

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        forms.uncheckMainToggleCheckbox(formDOM)

        if (response.data.items) {
          if (Array.isArray(response.data.items)) {
            response.data.items.forEach((item) => {
              document.getElementById(item._id).remove()
            })
          } else {
            console.log(response.data.items)
            document.getElementById(response.data.items._id).remove()
          }
        } else if (response.data.deletedAll) {
          let allRows = document.querySelectorAll("[data-row]")
          allRows.forEach((item) => {
            item.remove()
          })
        }
      }
    }

    const data = new FormData(formDOM)

    let deleteItem = []

    for (var pair of data.entries()) {
      if (pair[0].includes("[]")) {
        deleteItem.push(pair[1])
      }
    }

    if (deleteItem.length <= 0) {
      return
    }

    data.append("projectId", projectId)

    sendDataHandler({
      method: "delete",
      url: `/api/user/project-assign`,
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                     REMOVE PROJECT FROM PROTEGE DETAIL                     */
/* -------------------------------------------------------------------------- */
function removeProjectFromProtege(btn) {
  const projectId = btn.getAttribute("data-projectId")
  const id = btn.getAttribute("data-id")

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: "Przypisany projekt został usunięty." })
      statusModal.toggleModal()
      document.getElementById(projectId).remove()
    }
  }

  const data = new FormData()
  data.append("projectId", projectId)
  data.append("id", id)

  sendDataHandler({
    method: "delete",
    url: `/api/protege/remove-project`,
    data,
    callback,
  })
}

window.removeProjectFromProtege = removeProjectFromProtege

/* -------------------------------------------------------------------------- */
/*                           ADD PROJECT TO PROTEGE                           */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-assign-project")
  btn.addEventListener("click", () => {
    const selectDOM = document.querySelector(btn.getAttribute("data-select"))
    const id = btn.getAttribute("data-id")
    const selectedValue = selectDOM.querySelector("select").value
    const tableDOM = document.querySelector(btn.getAttribute("data-table"))

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        console.log(response.data)
        const rowDOM = renderTableRow(response.data.item)
        tableDOM.appendChild(rowDOM)
      }
    }

    function renderTableRow(project) {
      const rowDOM = document.createElement("div")
      rowDOM.className = "table-row"
      rowDOM.id = project._id

      const html = `
          <div class="table-col table-col--auto h-ai-c h-jc-sb">
            <span>${project.title}</span>
            <img src="/${project._avatar.filepath}" alt="Ikona projektu" class='icon icon--sm'>
          </div>
          <div class="table-col table-col--md h-flex h-jc-sb h-ml-2">
            <div class="btn btn--red h-fw" onclick="removeProjectFromProtege(this)" data-id="${id}" data-projectId="${project._id}">Usuń</div>
          </div>
        `
      rowDOM.innerHTML = html
      return rowDOM
    }

    const data = new FormData()
    data.append("id", id)
    data.append("projectId", selectedValue)

    sendDataHandler({
      method: "put",
      url: `/api/protege/assign-project`,
      data,
      callback,
    })
  })
} catch (err) {}

/**
 * Set farewellDate for protege-detail
 */
try {
  const setFarewell = document.querySelector(".js-set-farewell")
  setFarewell.addEventListener("click", () => {
    const selectDOM = document.querySelector(setFarewell.getAttribute("data-select"))
    const id = setFarewell.getAttribute("data-id")
    const selectedValue = selectDOM.querySelector("select").value

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData()
    data.append("farewellDate", selectedValue)

    sendDataHandler({
      method: "put",
      url: `/api/protege?id=${id}`,
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                       ADD VOLUNTEER INTO PROJECT LIST                      */
/* -------------------------------------------------------------------------- */
try {
  const addToList = document.querySelector(".js-add-to-list-volunteer")
  addToList.addEventListener("click", () => {
    const selectDOM = document.querySelector(addToList.getAttribute("data-select"))
    const id = addToList.getAttribute("data-id")
    const selectedValue = selectDOM.querySelector("select").value
    const tableDOM = document.querySelector(addToList.getAttribute("data-table"))

    const callback = (flag, response) => {
      console.log("response", response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        const rowDOM = renderTableRow(response.data.person)
        tableDOM.appendChild(rowDOM)

        forms.checkToggleCheckboxes(".js-toggle-checboxes-wrapper-2")
      }
    }

    function renderTableRow(volunteer) {
      console.log("volunteer", volunteer)
      const rowDOM = document.createElement("div")
      rowDOM.className = "table-row"
      rowDOM.id = volunteer._id

      const html = `
          <div class="table-col col-lg-7 h-ai-c h-flex h-jc-sb">${volunteer.fullname}</div>
          <div class="table-col col-lg-4 h-ml-2 h-ai-c h-flex h-jc-sb">${volunteer.email}</div>
          <div class="table-col col-lg-4 h-ml-2 h-ai-c h-flex h-jc-sb">${volunteer.phone}</div>
          <div class="table-col--pad-lg col-lg-3 h-ml-2 h-jc-e">
            <div class="form-group form-checkbox">
              <input type="checkbox" id="checkbox-${volunteer._id}" name="id[]" value="${volunteer._id}">
              <label for="checkbox-${volunteer._id}"></label>
            </div>
          </div>
				`

      rowDOM.innerHTML = html
      return rowDOM
    }

    const data = new FormData()
    data.append("id", selectedValue)
    data.append("projectId", id)

    sendDataHandler({
      method: "put",
      url: `/api/user/assign-project`,
      data,
      callback,
    })
  })
} catch (e) {}

/**
 * Delete checked Volunteers in project-detail
 */
try {
  const deleteBtn = document.querySelector(".js-remove-checked2")

  deleteBtn.addEventListener("click", () => {
    const formDOM = document.querySelector(deleteBtn.getAttribute("data-form"))
    const projectId = deleteBtn.getAttribute("data-id")

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        forms.uncheckMainToggleCheckbox(formDOM)

        if (response.data.items) {
          if (Array.isArray(response.data.items)) {
            response.data.items.forEach((item) => {
              document.getElementById(item._id).remove()
            })
          } else {
            console.log(response.data.items)
            document.getElementById(response.data.items._id).remove()
          }
        } else if (response.data.deletedAll) {
          let allRows = document.querySelectorAll("[data-row]")
          allRows.forEach((item) => {
            item.remove()
          })
        }
      }
    }

    const data = new FormData(formDOM)

    let deleteItem = []

    for (var pair of data.entries()) {
      if (pair[0].includes("[]")) {
        deleteItem.push(pair[1])
      }
    }

    if (deleteItem.length <= 0) {
      return
    }

    data.append("projectId", projectId)

    sendDataHandler({
      method: "delete",
      url: `/api/user/project-assign`,
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */


try {
  const loginButton = document.querySelector(".js-login-button")
	const roleSelector = document.querySelector('.js-role-selector')

  loginButton.addEventListener("click", (e) => {
    e.preventDefault()
    const formDOM = document.querySelector(loginButton.getAttribute("data-form"))
    const formWrapper = document.querySelector(".form-wrapper")

    formWrapper.classList.add("is-loading")
    formWrapper.classList.remove("failed")
    formWrapper.classList.remove("success")

    const callback = (flag, response) => {
      formWrapper.classList.remove("is-loading")

      if (response.data.success) {
        formWrapper.classList.add("success")
        setTimeout(function () {
          if (loginButton.getAttribute("data-url") == "user") {
            window.location.href = "/"
          } else {
            window.location.href = "/admin"
          }
        }, 1000)
      } else {
				if(response.data.needRole){
					roleSelector.classList.remove('hidden')
				}
        formWrapper.classList.add("failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "POST",
      url: "/api/login",
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                                     FAQ                                    */
/* -------------------------------------------------------------------------- */
try {
  const accordion_faq = new Accordion(".js-accordion", {
    items: ".js-accordion__header",
  })

  const createButton = document.querySelector(".js-submit-create-faq")

  createButton.addEventListener("click", () => {
    const callback = (flag, response) => {
      console.log(response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()

        const item = document.createElement("li")
        item.classList = "accordion__item h-mt-1rem"
        item.innerHTML = renderItem(response.data.saved)
        document.querySelector(".js-faq-wrapper").appendChild(item)

        accordion_faq.recache()
      }
    }

    const data = new FormData(document.querySelector(".js-form-create-faq"))

    const renderItem = (item) => {
      return `
      <li class="accordion__item h-mt-1rem">
        <header class="accordion__header js-accordion__header">
          <div class="accordion__icon"></div>
          <div class="accordion__heading">${item.title}</div>
        </header>
        <div class="accordion__body">
          <div class="accordion__desc">${item.content}</div>
        </div>
      </li>`
    }

    sendDataHandler({
      method: "post",
      url: "/api/faq",
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              CHANGE PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const changePasswordButton = document.querySelector(".js-change-password-button")
  changePasswordButton.addEventListener("click", (e) => {
    e.preventDefault()
    const formDOM = document.querySelector(changePasswordButton.getAttribute("data-form"))
    const formWrapper = document.querySelector(".form-wrapper")

    formWrapper.classList.add("is-loading")
    formWrapper.classList.remove("failed")
    formWrapper.classList.remove("success")

    const callback = (flag, response) => {
      formWrapper.classList.remove("is-loading")
      console.log(response)

      if (response.data.success) {
        formWrapper.classList.add("success")
        setTimeout(function () {
          window.location.href = "/logowanie"
        }, 1000)
      } else {
        formWrapper.classList.add("failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)
    let url = "/logowanie/zmien-haslo"

    sendDataHandler({
      method: "POST",
      url,
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                              FORGOT PASSWORD                               */
/* -------------------------------------------------------------------------- */
try {
  const changePasswordButton = document.querySelector(".js-forgot-pass-button")
  changePasswordButton.addEventListener("click", (e) => {
    e.preventDefault()
    const formDOM = document.querySelector(changePasswordButton.getAttribute("data-form"))
    const formWrapper = document.querySelector(".form-wrapper")

    formWrapper.classList.add("is-loading")
    formWrapper.classList.remove("failed")
    formWrapper.classList.remove("success")

    const callback = (flag, response) => {
      formWrapper.classList.remove("is-loading")

      if (response.data.success) {
        formWrapper.classList.add("success")
      } else {
        formWrapper.classList.add("failed")
        document.querySelector(".error-message").innerHTML = response.data.message
      }
    }

    const data = new FormData(formDOM)
    let url = "/api/remind-password"

    sendDataHandler({
      method: "PUT",
      url,
      data,
      callback,
    })
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                               REPORT PROBLEM                               */
/* -------------------------------------------------------------------------- */
try {
  const sendProblem = document.querySelector(".js-submit-create-problem")

  sendProblem.addEventListener("click", () => {
    let formDOM = document.querySelector(sendProblem.getAttribute("data-form"))

    const callback = (flag, response) => {
      console.log(response)
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(formDOM)

    sendDataHandler({
      method: "post",
      url: `/api/report-problem`,
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                               Create Donation                              */
/* -------------------------------------------------------------------------- */

try {
  const btn = document.querySelector(".js-create-donate-btn")
  const form = document.querySelector(".js-create-donate-form")

  btn.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(form)

    sendDataHandler({
      method: "post",
      url: "/api/donation",
      data,
      callback,
    })
  })
} catch (err) {}

/** FETCH KG DOBRA */
try {
  const donateHistoryWrapperDOM = document.querySelector(".js-donate-history")
  const loadersDOM = donateHistoryWrapperDOM.querySelectorAll(".js-loader-wrapper")

  const activationCallback = (item, dataTab) => {
    const id = item.getAttribute("data-id")

    const callback = (flag, response) => {
      if (flag) {
        setTimeout(() => {
          loadersDOM.forEach((loader) => loader.classList.remove("is-active"))
          donateHistoryWrapperDOM.querySelector(".js-donate-fullname").innerHTML = response.data.item.fullname
          donateHistoryWrapperDOM.querySelector(".js-donate-email").innerHTML = response.data.item.email
          donateHistoryWrapperDOM.querySelector(".js-donate-phone").innerHTML = response.data.item.phone
          donateHistoryWrapperDOM.querySelector(".js-donate-date").innerHTML = moment(response.data.item.date).format("DD.MM.YYYY") + " r."
          donateHistoryWrapperDOM.querySelector(".js-donate-amount").innerHTML = response.data.item.amount + " zł"
          donateHistoryWrapperDOM.querySelector(".js-donate-weight").innerHTML = response.data.item.weight + " kg"
          donateHistoryWrapperDOM.querySelector(".js-donate-unit").innerHTML = response.data.item.unit
          donateHistoryWrapperDOM.querySelector(".js-donate-dedication").innerHTML = response.data.item.dedication
          donateHistoryWrapperDOM.querySelector(".js-donate-document").innerHTML = response.data.item._confirmation
            ? `<a href="/${response.data.item._confirmation.filepath}" target="_blank">Załącznik</a>`
            : "Brak"
          let unit
          if (response.data.item.unitSize === "large") unit = "Duże jednostki skarbowe 120 pracowników"
          if (response.data.item.unitSize === "medium") unit = "Średnie jednostki skarbowe 60-120 pracowników"
          if (response.data.item.unitSize === "small") unit = "Małe jednostki skarbowe do 60 pracowników"
          donateHistoryWrapperDOM.querySelector(".js-donate-unitSize").innerHTML = unit
        }, 600)
      } else {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message || "Coś poszło nie tak." })
        statusModal.toggleModal()
      }
    }

    if (id) {
      loadersDOM.forEach((loader) => loader.classList.add("is-active"))
      donateHistoryWrapperDOM.querySelector(".js-donate-fullname").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-email").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-phone").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-date").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-amount").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-weight").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-unit").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-unitSize").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-dedication").innerHTML = ""
      donateHistoryWrapperDOM.querySelector(".js-donate-document").innerHTML = ""

      sendDataHandler({
        method: "get",
        url: `/api/project/kilogramy-dobra?id=${id}`,
        callback,
      })
    } else {
    }
  }

  const tabs = new Tabs(".js-tabs", {
    navItems: ".js-nav-tab__item--kgdobra",
    items: ".js-tab__item--kgdobra",
    activationCallback,
  })
} catch (e) {}

/* -------------------------------------------------------------------------- */
/*                            SEND MESSAGE AS USER                            */
/* -------------------------------------------------------------------------- */
try {
  const sendMessage = document.querySelector(".js-send-message-user")
  sendMessage.addEventListener("click", () => {
    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
      }
    }

    const data = new FormData(document.querySelector(".js-form-message"))

    sendDataHandler({
      method: "post",
      url: "/wiadomosci/napisz-wiadomosc",
      data,
      callback,
    })
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                                FIRST LOGIN                                 */
/* -------------------------------------------------------------------------- */
try {
  const firstLogin = document.querySelector(".js-first-login")

  if (firstLogin) {
    const data = new FormData()
    data.append("firstLogin", true)

    setTimeout(() => {
      sendDataHandler({
        method: "post",
        url: "/api/user",
        data,
        callback: () => {},
      })
    }, 5000)
  }
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                           ILLATION BANK ACCOUNT                            */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-illation-bankaccount-btn")
  const form = document.querySelector(".js-illation-bankaccount-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    const cb = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
				statusModal.toggleModal()

				if(document.querySelector('.js-illation-cost-refund-form')) document.querySelector('.js-illation-cost-refund-form').classList.remove('is-inactive');
				if(document.querySelector('.js-illation-bankaccount-adnotation')) document.querySelector('.js-illation-bankaccount-adnotation').remove();

				const btns = form.querySelectorAll('.js-btn-edit-parent')
				btns.forEach(x => { x.classList.remove('is-active') })
      }
    }
		

		sendDataHandler({
			method: "put",
			url: "/api/user",
			data,
			callback: cb,
		})
  })
} catch (err) {}
/* -------------------------------------------------------------------------- */
/*                            ILLATION COST REFUND                            */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-illation-cost-refund-btn")
  const form = document.querySelector(".js-illation-cost-refund-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    const callback = (flag, response) => {
      if (!flag) {
        confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
        confirmModal.setFailed()
      } else {
        confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
        confirmModal.setSuccess()
        setTimeout(() => (window.location.href = "/wnioski"), 2000)
      }
    }

    const confirmCallback = () => {
      confirmModal.setLoading()
      sendDataHandler({
        method: "post",
        url: "/api/illation/cost-refund",
        data,
        callback,
      })
    }

    confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno wysłać wniosek?", confirmCallback })
    confirmModal.toggleModal()
  })
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                          ILLATION COST REFUND SAVE                         */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-illation-cost-refund-save-btn")
  const form = document.querySelector(".js-illation-cost-refund-form")

  btn.addEventListener("click", () => {
    const data = new FormData(form)

    const callback = (flag, response) => {
      if (!flag) {
        confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
        confirmModal.setFailed()
      } else {
        confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
        confirmModal.setSuccess()
        setTimeout(() => (window.location.href = "/wnioski/refundacja-kosztow"), 2000)
      }
    }

    const confirmCallback = () => {
      confirmModal.setLoading()
      sendDataHandler({
        method: "post",
        url: "/api/illation/cost-refund/save",
        data,
        callback,
      })
    }

    confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno chcesz zapisać wniosek?", confirmCallback })
    confirmModal.toggleModal()
  })

  window.deleteIllationRow = (item) => {
		console.log('item', item)
    item.parentNode.remove()
    const data = new FormData(form)

    const callback = (flag, response) => {
      if (!flag) {
        statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
        statusModal.toggleModal()
      } else {
        statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
        statusModal.toggleModal()
        window.location.href = "/wnioski/refundacja-kosztow"
      }
    }

    sendDataHandler({
      method: "post",
      url: "/api/illation/cost-refund/save",
      data,
      callback,
    })
  }
} catch (err) {}

/* -------------------------------------------------------------------------- */
/*                            ILLATION COST REFUND                            */
/* -------------------------------------------------------------------------- */
try {
  const btn = document.querySelector(".js-illation-status-btn")
  const form = document.querySelector(".js-illation-status-form")

  const infosDOM = document.querySelectorAll("[name='information[]']")
  infosDOM.forEach((x) => {
    if (x.value == "") {
      x.value += " "
    }
  })

  const callback = (flag, response) => {
    if (!flag) {
      statusModal.injectHTML({ title: `Błąd`, content: response.data.message })
      statusModal.toggleModal()
    } else {
      statusModal.injectHTML({ title: `Sukces`, content: response.data.message })
      statusModal.toggleModal()
      window.location.reload()
    }
  }

  btn.addEventListener("click", () => {
    sendDataHandler({
      method: "put",
      url: "/api/admin/illation",
      data: new FormData(form),
      callback: callback,
    })
  })
} catch (err) {}

/**
 * Archive donation
 */
window.archiveDonation = (id) => {
  const callback = (flag, response) => {
    if (!flag) {
      confirmModal.injectHTML({ title: `Błąd`, content: response.data.message })
      confirmModal.setFailed()
    } else {
      confirmModal.injectHTML({ title: `Sukces`, content: response.data.message })
      confirmModal.setSuccess()
      document.getElementById(id).remove()
    }
  }

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "post",
      url: `/api/donation/${id}?archive=1`,
      callback: callback,
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno zarchiwizować darowiznę?", confirmCallback })
  confirmModal.toggleModal()
}

/* -------------------------------------------------------------------------- */
/*                                  UNIVERSAL                                 */
/* -------------------------------------------------------------------------- */
window.postSubmit = async function (event) {
  event.preventDefault()
  const element = event.target
  const href = element.getAttribute("data-href")
  const data = new FormData(element)

  element.classList.add("is-loading")

  /** clean up errors */
  cleanUpElements("[data-error]")

  const [status, response] = await fetchWrapper({
    method: "POST",
    url: href,
    data: data,
  })
	console.log('postSubmit')

	if(element.querySelector('.js-message')){
		if (status >= 300) {
			element.querySelector('.js-message').textContent = response.message
		} else {
			element.querySelector('.js-message').textContent = response.message
		}
	}else{
		console.log('notify')
		if (status >= 300) {
			new Notify({ text: response.message, duration: 3000, type: "error" })
		} else {
			new Notify({ text: response.message, duration: 3000, type: "success" })
			if(response.hasToRefresh) window.location.reload();
		}
	}
}

window.putSubmit = async function (event) {
  event.preventDefault()
  const element = event.target
  const href = element.getAttribute("data-href")
  const data = new FormData(element)

  element.classList.add("is-loading")

  cleanUpElements("[data-error]")

  const [status, response] = await fetchWrapper({
    method: "PUT",
    url: href,
    data: data,
  })

	const btns = element.querySelectorAll('.js-btn-edit-parent')
	btns.forEach(x => { x.classList.remove('is-active') })

  if (status >= 300) {
    new Notify({ text: response.message, duration: 3000, type: "error" })
  } else {
    new Notify({ text: response.message, duration: 3000, type: "success" })
  }

  // const callback = (flag, response) => {
  //   event.target.classList.remove("is-loading")
  //   console.log("FLAGA: ", flag)
  //   if (!flag) {
  //     console.log(response.error, response.error.fields)
  //     if (response.error && response.error.fields) {
  //       renderErrorsToForm(response.error.fields, element)
  //     } else {
  //       statusModal.injectHTML({ title: `Błąd`, content: response.message })
  //       statusModal.toggleModal()
  //     }
  //   } else {
  //     new Notify({ text: response.message, duration: 3000, type: "success" })
  //   }
  // }

  // fetchWrapper({
  //   method: "PUT",
  //   url: href,
  //   data,
  //   callback,
  // })
}

window.deleteSubmit = function (event) {
  event.preventDefault()
  const element = event.target
  const href = element.getAttribute("data-href")
  const id = element.getAttribute("data-id")

  const confirmCallback = () => {
    confirmModal.setLoading()
    sendDataHandler({
      method: "DELETE",
      url: href,
      callback: (flag, response) => defaultDeleteCallback(flag, response, id),
    })
  }

  confirmModal.injectHTML({ title: `Potwierdź`, content: "Czy na pewno to usunąć?", confirmCallback })
  confirmModal.toggleModal()
}
