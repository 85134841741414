// const fetchWrapper = async ({ method, url, data, callback, type }) => {
//   const csrfToken = document.querySelector("[name=_csrf]").getAttribute("content")

//   if (data) {
//     data.append("_csrf", csrfToken)
//   }

//   // Set content-type to none, so the browser can choose the best one that fits body
//   let fetchOptions = {
//     method,
//     credentials: "same-origin",
//     headers: {},
//     body: data,
//   }

//   let response = await fetch(url, fetchOptions)
//   console.log(response)
//   let status = response.status

//   if (status == 404) {
//     return callback(false, { error: { message: "Coś poszło nie tak" } })
//   }
//   console.log(response)
//   response = await response.json()

//   console.log("status", status)

//   // setTimeout(() => {
//   if (status > 300) {
//     callback(false, response)
//   } else {
//     callback(true, response)
//   }
//   // }, 500)
// }

// export default fetchWrapper

async function fetchWrapper({ url, method, body, data, headers }) {
  let formData
  if (method !== "GET" && body) {
    formData = new FormData()
    for (const key in body) {
      // when value is array, for example multiple files
      if (Array.isArray(body[key])) {
        body[key].forEach((x) => {
          formData.append(key, x)
        })
      } else {
        formData.append(key, body[key])
      }
    }
  }

  /**
   * Convert body into querystring for GET method request
   */
  if (method === "GET" && body) {
    const qs = Object.keys(body)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
      .join("&")
    url = url + `?${qs}`
  }

  let response = await fetch(url, {
    method,
    credentials: "include",
    headers: headers || {},
    body: formData || data || null,
  })

  let status = response.status

  const responseText = await response.text()
  try {
    response = JSON.parse(responseText)
  } catch (e) {
    console.log(e)
    if (status === 401) response = { message: "Unauthorized" }
  }

  if (response.success == false) status = 304

  return [status, response]
}

export default fetchWrapper
