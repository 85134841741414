/**
 * elements: valid querySelector property
 * property: what property have to be cleaned
 */
const cleanUpElements = (elements, property = "innerHTML") => {
	const errorFields = document.querySelectorAll(elements)
	errorFields.forEach(x => x[property] = "")
}

const renderErrorsToForm = (fields, wrapper = document) => {
	Object.keys(fields).forEach(key => {
		const el = wrapper.querySelector(`[data-error="${key}"]`)
		if(el){
			if(Array.isArray(fields[key])){
				el.innerHTML = fields[key][0]
			}else{
				el.innerHTML = fields[key]
			}
		}
	})
}

export {
	cleanUpElements,
	renderErrorsToForm
}