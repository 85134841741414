import forms from "../modules/forms"

// window.chooseFile = (el) => {
//   const parentDOM = el.closest(".js-form-group")
//   if (!parentDOM || !uploadModal) return

//   const inputDOM = parentDOM.querySelector("input")
//   const filenameDOM = parentDOM.querySelector(".js-filename")

//   const callback = () => {
//     uploadModal.toggleModal()
//     inputDOM.value = uploadModal.selectedImage._id
//     filenameDOM.innerHTML = uploadModal.selectedImage.filename
//   }

//   uploadModal.toggleModal({ callbackOnModalClose: () => callback() })
// }

forms.showFormGroup(".js-form-group-hidden")
forms.expandText({ wrapper: ".js-expand-wrapper" })
forms.summarizeInputs(".js-render-summarize", "data-summarize")

window.enableEditHandler = forms.enableEditHandler
window.formatBankAccount = forms.formatBankAccount
window.fileInputChange = forms.fileInputChange
window.formatNumber = forms.formatNumber

