import * as Vue from 'vue';

import FileManager from './component/filemanager'
import Pagination from './component/pagination'

const app = Vue.createApp(FileManager)
if(document.querySelector('#vue-pagination')) Pagination(app);

app.mount('#v-filemanager')
const filemanager = document.querySelector('#v-filemanager')
if(filemanager) filemanager.style.display="block"