const toggler = ({toggleButton, toggledItem}) => {
  let clickEvent;

  const toggleBtnDOM = document.querySelector(toggleButton);
  const toggledItemDOM = document.querySelector(toggledItem);

  toggleBtnDOM.addEventListener('click', () => toggle(true));

  function toggle(flag){
    if(flag == true){
      /** Pass event parameter to clickOutside function and attach event to listener */
      clickEvent = (event) => clickOutside(event);
      document.addEventListener('click', clickEvent, true);

      toggledItemDOM.classList.add('is-active');
    }else if(flag == false){
      toggledItemDOM.classList.remove('is-active');

      /** Remove clickOutside event */
      document.removeEventListener('click', clickEvent, true);
    }
  }

  function clickOutside(event){
    /** Prevents to call this function more then one in such event */
    event.stopImmediatePropagation();

    /** if click happends outside of wrapperDOM then close notifications */
    if (!toggledItemDOM.contains(event.target)) {
      toggle(false);
    }
  }
}

export default toggler;