import Notifications from "../modules/notifications"

try {
  new Notifications(".js-notification-wrapper", {
    toggler: ".js-notifications-toggler",
    notificationItems: ".js-notification-item",
    notificationItemDelete: ".js-notification-delete",
    notificationDeleteAll: ".js-notification-delete-all",
    notificationScroll: ".js-notification-scroll",
    notificationLoader: ".js-notification-loader",
    notificationNotReadCounter: ".js-notification-notread-counter",
  })
} catch (e) {}