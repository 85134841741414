import { Jodit } from "jodit"

if(typeof CKEDITOR !== 'undefined'){
	CKEDITOR.plugins.add( 'filemanager', {
    icons: 'image',
    init: function( editor ) {
      editor.addCommand( 'insertFile', {
				exec: function( editor ) {
					const callback = (result) => {
						if(Array.isArray(result.value)){
							const filepath = result.value[0].filepath
							editor.insertHtml(`<img src="https://crm.fundacjaskarbowosci.pl/${filepath}" />`);
						}
					}

					chooseFile({ max: 1 }, callback);
				}
			});
			editor.ui.addButton( 'Filemanager', {
				label: 'Dodaj zdjęcie',
				command: 'insertFile',
				toolbar: 'insert',
				icon: '/client/images/image.png',
			});
    }
	});
}


function showFormGroup(parent) {
  const parentDOM = document.querySelectorAll(parent)
  const triggerRadio = function(radio, parent, flag = true) {
    const hiddenForm = parent.querySelector(".form-group-hidden")

    if (radio.getAttribute("data-show-group") !== null && radio.checked == true) {
      hiddenForm.classList.add("is-shown")
    } else {
      flag && hiddenForm.classList.remove("is-shown")
    }
  }

  parentDOM.forEach(parent => {
    const radioButtons = parent.querySelectorAll("[type=radio]")

    // check for radios after first render
    radioButtons.forEach(radio => triggerRadio(radio, parent, false))

    radioButtons.forEach(radio =>
      radio.addEventListener("change", function() {
        triggerRadio(this, parent)
      })
    )
  })
}

function checkForEmptyInput(field) {
  const fieldDOM = document.querySelector(`[name="${field}"]`)
  fieldDOM.classList.add("is-invalid")
  ;["keyup", "change"].forEach(e => {
    fieldDOM.addEventListener(e, function() {
      if (this.value.length > 0) {
        this.classList.remove("is-invalid")
      } else {
        this.classList.add("is-invalid")
      }
    })
  })
}

const enableEditHandler = (item, form) => {
  const btnEditParent = item.closest(".js-btn-edit-parent")
  btnEditParent.classList.add("is-active")

  const formDOM = document.querySelector(form)
  const formInputsDOM = formDOM.querySelectorAll(".is-uneditable")
  formInputsDOM.forEach(input => {
    input.classList.remove("is-uneditable")
  })

  // const wysiwygDOM = formDOM.querySelector('[data-wysiwyg]');
  // let jodit;
  // if(wysiwygDOM){
  //   jodit = new Jodit(wysiwygDOM);
  // }
}

const disableEditing = (btnDOM, formDOM) => {
  const btnParentDOM = btnDOM.closest(".js-btn-edit-parent")
  btnParentDOM.classList.remove("is-active")

  const disabledElements = formDOM.querySelectorAll("[data-edit]")
  disabledElements.forEach(elem => elem.classList.add("is-uneditable"))

  // const wysiwygDOM = formDOM.querySelector('.jodit-container');
  // if(wysiwygDOM){
  //   wysiwygDOM.destruct();
  // }
}

const checkToggleCheckboxes = form => {
  /** it could be an utility method, that would return node and checking if provided variable is node */
  let formDOM
  if (form instanceof Element || form instanceof HTMLDocument) {
    formDOM = form
  } else {
    formDOM = document.querySelector(form)
  }

  const allCheckboxes = formDOM.querySelectorAll('[type="checkbox"]')

  let mainCheckboxDOM
  allCheckboxes.forEach(ck => {
    if (ck.getAttribute("data-main-checkbox") !== null) {
      mainCheckboxDOM = ck
    }

    ck.addEventListener("change", event => {
      if (event.target.getAttribute("data-main-checkbox") !== null) {
        allCheckboxes.forEach(ck => (ck.checked = event.target.checked))

        // checkbox is checked
      } else if (event.target.checked) {
        /**
         * if all checkboxes were checked manually
         * then we should sign as checked the main checkbox
         */
        let flag = true
        allCheckboxes.forEach(ck => {
          if (ck.checked === false && !(ck.getAttribute("data-main-checkbox") !== null)) {
            flag = false
          }
        })

        if (flag) {
          mainCheckboxDOM.checked = event.target.checked
        }

        // checkbox is unchecked
      } else {
        mainCheckboxDOM.checked = event.target.checked
      }
    })
  })
}

const uncheckMainToggleCheckbox = form => {
  let formDOM
  if (form instanceof Element || form instanceof HTMLDocument) {
    formDOM = form
  } else {
    formDOM = document.querySelector(form)
  }

  const mainCheckbox = formDOM.querySelector("[data-main-checkbox]")
  mainCheckbox.checked = false
}

const removeDocument = id => {
  document.getElementById(id).remove()
}

const expandText = ({ wrapper }) => {
  const wrapperDOM = document.querySelectorAll(wrapper)

  wrapperDOM.forEach(wrapp => {
    const btnDOM = wrapp.querySelector(".js-expand-button")
    // const expandBoxDOM = wrapp.querySelector('.js-expand-box');

    btnDOM.addEventListener("click", () => wrapp.classList.toggle("is-expanded"))
  })
}

const formatBankAccount = e => {
  // When user select text in the document, also abort.
  const selection = window.getSelection().toString()
  if (selection !== "") return

  // When the arrow keys are pressed, abort.
  if ([38, 40, 37, 39].includes(event.keyCode)) return

  let input = e.target.value
  input = input.replace(/\D/g, "")

  var split = 2
  var chunk = []

  for (var i = 0, len = input.length; i < len; i += split) {
    split = i < 2 ? 2 : 4
    chunk.push(input.substr(i, split))
  }

  if (chunk.length > 7) chunk.length = 7

  e.target.value = chunk.join("-")
}

const fileInputChange = function(event) {
  const input = event.target
  const val = input.value
  var valsplit = " "
  if (input.files.length > 1) {
    valsplit = input.files.length + " Pliki"
  } else {
    valsplit = val.split("\\").pop()
  }

  valsplit == "" ? (valsplit = "Dodaj") : null
  event.target.parentNode.querySelector("label").innerHTML = valsplit
}

/**
 * Renders summarized value from inputs with the same data-tag
 * @param {node_class} element
 * @param {data_tag_attribute} dataHook
 */
const summarizeInputs = function(element, dataHook){
  const elements = document.querySelectorAll(element);
  elements.forEach(el => {
    const hook = el.getAttribute(dataHook);

    const inputs = document.querySelectorAll(`input[data-summarize="${hook}"]`);

    inputs.forEach(input => input.addEventListener('change', () => {

      const sum = summarize(inputs, el);
      el.innerHTML = sum;
    }))
  })

  const summarize = (inputs, el) => {
    const checkSelect = el.getAttribute('data-check');
    let hasToCheckSelect = false;
    if(checkSelect) hasToCheckSelect = true;

    let sum = 0;
    inputs.forEach((input) => {
      if(hasToCheckSelect){
        const select = input.parentNode.parentNode.parentNode.querySelector('[name="status[]"]')
        const value = select.value;
        if(value != 1){
          return;
        }
      }

      sum += +input.value;
    })

    return sum.toFixed(2);
  }
}

function formatNumber(event, delimiter = '.', toFixed){
	console.log(event.target.value)
	if(event.target.value != ""){
		let normalized = event.target.value.replace(/,/g, delimiter)
		if(toFixed) normalized = Number(normalized).toFixed(toFixed)
		event.target.value = normalized
	}
	console.log(event.target.value)
}

try{
	const editorsDOM = document.querySelectorAll("[data-wysiwyg]")
	editorsDOM.forEach(editor => {
		CKEDITOR.replace(editor, {
			on: {
				change: function() {
						this.updateElement();
				}
			}
		})
		// let jodit = new Jodit(editor, {
		// 	extraButtons: [
		// 		{
		// 			name: 'remove-styles',
		// 			tooltip: 'Usuń style',
		// 			iconURL: '/client/images/clear-styles.png',
		// 			exec: function (editor) {
		// 				const text = editor.editor.innerHTML;
		// 				let finalText = text.replace(/style=\".*"/gm,'')
		// 				editor.editor.innerHTML = finalText
		// 			}
		// 		},
		// 		{
		// 			name: 'remove-styles',
		// 			tooltip: 'Usuń formatowanie',
		// 			iconURL: '/client/images/clear.png',
		// 			exec: function (editor) {
		// 				const text = editor.editor.innerHTML;
		// 				let finalText = text.replace(/<\/?[^>]+(>|$)/g, "");
		// 				editor.editor.innerHTML = finalText
		// 			}
		// 		}
		// 	]
		// })
	})
}catch(err){ console.log('CKEDIITOR', err) }


try{
	const editorsDOM = document.querySelectorAll("[data-wysiwyg-image]")
	editorsDOM.forEach(editor => {
		CKEDITOR.replace(editor, {
			extraPlugins: 'filemanager',
			on: {
				change: function() {
						this.updateElement();
				}
			}
		})
		// let jodit = new Jodit(editor, {
		// 	extraButtons: [
		// 		{
		// 			name: 'filepicker',
		// 			tooltip: 'Filepicker',
		// 			iconURL: '/client/images/image.png',
		// 			exec: function (editor) {
		// 				const callback = () => {
		// 					uploadModal.toggleModal()
		// 					editor.selection.insertHTML(`<img src="https://crm.fundacjaskarbowosci.pl/${uploadModal.selectedImage.filepath}" />`);
		// 				}
		// 				uploadModal.toggleModal({ callbackOnModalClose: () => callback() })
		// 			}
		// 		}
		// ]
		// })
	})
}catch(err){

}

export default {
  showFormGroup,
  checkForEmptyInput,
  enableEditHandler,
  disableEditing,
  checkToggleCheckboxes,
  removeDocument,
  uncheckMainToggleCheckbox,
  expandText,
  formatBankAccount,
  fileInputChange,
	summarizeInputs,
	formatNumber
}
