import axios from "../vendors/axios"

const sendDataHandler = async ({ method, url, data, callback, type }) => {
  const csrfToken = document.querySelector("[name=_csrf]").getAttribute("content")

  const options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/x-www-form-urlencoded",
      "CSRF-Token": encodeURIComponent(csrfToken)
    }
  }

  if (type == "files") {
    options.headers["Content-Type"] = "multipart/form-data"
    options.headers["Accept"] = "multipart/form-data"
    options.headers["enctype"] = "multipart/form-data"
  }

  /**
   * Fix for FormData data. When data is type of FormData then axios (WHY?) provides 'Content-Type': 'multipart/form-data' which, can't be parsed on backend.
   * It happens even if we provide options.headers... it just can't be overwritten :(
   */

  // let json
  // if (type != "files" && data instanceof FormData) {
  //   json = Object.fromEntries(data)
  // }

  // const response = await axios({ method, url, data: json || data, options })
  const response = await axios({ method, url, data: data, options })

  if (response.data.success == false || response.status > 300) {
    callback(false, response)
  } else {
    callback(true, response)
  }
}

export default sendDataHandler
