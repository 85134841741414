import axios from 'axios';

let baseURL = '/';

const instance = axios.create({
  baseURL,
  validateStatus: function (status) {

    return status >= 200 && status <= 503;
  },
});

instance.defaults.withCredentials = true;
instance.defaults.credentials = 'include';

export default instance;